<template>
    <div class="GraphSelectContainer q-card q-pa-md relative-position">
        <q-btn
            flat
            color="primary"
            icon="close"
            class="absolute-top-right"
            @click="$emit('remove', index)"
        />
        <div
            v-if="!emptyRows"
            class="ChartContainer"
        >
            <v-chart
                ref="chart"
                :option="barChartOptions"
                class="Chart"
            />
        </div>
        <div
            v-else
            class="flex flex-center q-mx-md q-my-lg"
        >
            <WText>
                {{ $t('visualization.customPlotPanels.attributesInfo.noData') }}
            </WText>
        </div>
        <div class="OptionsContainer q-mt-sm q-mx-sm q-gutter-sm">
            <q-select
                v-model="attribute"
                class="attrSelect"
                :options="optionAttributes.map(i => i.name)"
                :label="$t('visualization.customPlotPanels.attributesInfo.selectAttribute')"
                @update:modelValue="getData"
            />
            <q-toggle
                v-model="filtersEnabled"
                :label="$t('visualization.attributes.filtersEnabled')"
                size="xs"
                @update:model-value="getData"
            />
            <div class="text-caption text-bold">
                {{ !numericAttribute? $t('visualization.attributes.categorical') : $t('visualization.attributes.number') }}
            </div>
            <div
                v-if="!numericAttribute"
                class="column"
            >
                <div class="column">
                    <div class="text-caption text-left">
                        {{ $t('visualization.customPlotPanels.attributesInfo.sortBy') }}
                    </div>
                    <q-btn-toggle
                        v-model="currentSel.value"
                        toggleColor="primary"
                        noCaps
                        unelevated
                        padding="2px 10px"
                        :options="freqDur"
                        @update:modelValue="getData"
                    />
                </div>
                <q-toggle
                    v-model="eventAttribute"
                    :label="$t('visualization.customPlotPanels.attributesInfo.eventAttribute')"
                    size="xs"
                    class="q-my-sm"
                    @update:modelValue="getData"
                />
                <q-select
                    v-if="eventAttribute"
                    ref="activities"
                    v-model="activities"
                    :label="$t('visualization.customPlotPanels.attributesInfo.selectActivity')"
                    :multiple="true"
                    :options="optionActivities"
                    class="Field"
                    :rules="[value => validators.required(value.length > 0) || $t('visualization.customPlotPanels.attributesInfo.activityRequired')]"
                    @update:modelValue="handleStandardActivitiesInput"
                >
                    <template #option="scope">
                        <QItem v-bind="scope.itemProps">
                            <QItemSection>
                                <QItemLabel v-html="scope.opt" />
                            </QItemSection>
                            <QItemSection side>
                                <QToggle
                                    v-model="scope.selected"
                                    :val="scope.opt"
                                    @update:modelValue="handleActivitiesInput(scope.opt)"
                                />
                            </QItemSection>
                        </QItem>
                    </template>
                </q-select>
            </div>
            <div
                v-else
                class="column q-gutter-sm"
            >
                <QSelect
                    v-model="timeUnit"
                    class="timeUnitField"
                    dense
                    :label="$t('visualization.customPlotPanels.attributesInfo.selectUnit')"
                    :options="optionTimeUnit"
                    @update:modelValue="getData"
                />
                <div
                    class="q-pa-xs"
                    style="display:flex; flex-direction:row; justify-content: space-around;"
                >
                    <div style="display:flex; flex-direction:column;">
                        <div><strong>avg:</strong> {{ avg }}</div>
                        <div><strong>min:</strong> {{ min }}</div>
                    </div>
                    <div style="display:flex; flex-direction:column;">
                        <div><strong>max:</strong> {{ max }}</div>
                        <div><strong>sd:</strong> {{ sd }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { filtersMixin, validatorsMixin, filtersStorageMixin } from '@/mixins'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'AttributeComponent',
    mixins: [filtersMixin, validatorsMixin, filtersStorageMixin],
    inject: ['App'],
    props: {
        attributes: VueTypes.array,
        categoricalAttributes: VueTypes.array,
        processId: VueTypes.string,
        configuration: VueTypes.object,
        index: VueTypes.number,
    },
    emits: ['remove', 'update', 'chartLoading'],
    data () {
        return {
            filtersEnabled: true,
            title: this.currentTitle,
            selected: false,
            select: null,
            range: '',
            model: null,
            modifiedFilters: [],
            duration: null,
            stringOptions: '',
            metricOptions: [{
                key: 'FD', label: this.$t('visualization.customPlotPanels.frequencyAndDuration'), disabled: false, icon: 'timelapse',
            },
            {
                key: 'AI', label: this.$t('visualization.customPlotPanels.attributeInfo'), disabled: true, icon: 'query_stats',
            }],
            attribute: null,
            optionAttributes: [],
            eventAttribute: false,
            activities: [],
            selectedActivities: [],
            optionActivities: [],
            activitiesTimer: null,
            emptyRows: false,
            maxDur: 'days',
            numericAttribute: false,
            optionTimeUnit: [
                { unit: 'month', label: this.$t('visualization.customPlotPanels.attributesInfo.month') },
                { unit: 'week', label: this.$t('visualization.customPlotPanels.attributesInfo.week') },
                { unit: 'day', label: this.$t('visualization.customPlotPanels.attributesInfo.day') },
            ],
            timeUnit: { unit: 'month', label: this.$t('visualization.customPlotPanels.attributesInfo.month') },
            currentSel: {
                value: 'FREQUENCY',
                label: this.$t('visualization.customPlotPanels.frequency'),
            },
            avg: undefined,
            min: undefined,
            max: undefined,
            sd: undefined,
            barChartOptions: {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {},
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => `${this.formatNumber(value)}${this.range}`,
                        },
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => this.formatNumber(value),
                        },
                    },
                ],
                grid: {
                    left: 45,
                    right: 45,
                    bottom: 23,
                    top: 20,
                },
                series: [
                    {
                        name: this.$t('visualization.customPlotPanels.duration'),
                        type: 'line',
                        data: [],
                        itemStyle: {
                            color: '#33699A',
                        },
                        yAxisIndex: 0,
                    },
                    {
                        name: this.$t('visualization.customPlotPanels.frequency'),
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: '#6C97BE',
                        },
                        yAxisIndex: 1,
                    },
                ],
            },
        }
    },
    computed: {
        freqDur () {
            return [{
                value: 'FREQUENCY',
                label: this.$t('visualization.customPlotPanels.frequency'),
            },
            {
                value: 'DURATION',
                label: this.$t('visualization.customPlotPanels.duration'),
            }]
        },
    },
    mounted () {
        this.attrSel()
        this.getSplitFilteredActivities()
        this.setConfiguration()
        this.getData()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        attrSel () {
            const optionAttr = this.attributes.filter((item) => {
                const isNumericType = ['BOOLEAN', 'SHORT', 'INTEGER', 'LONG', 'DECIMAL'].includes(item.type)
                const isStringAndInCategorical = item.type === 'STRING' &&
                    this.categoricalAttributes.some(cat => cat.name === item.name)
                return isNumericType || isStringAndInCategorical
            })
            this.resetChart()
            this.optionAttributes = optionAttr
            const attr = this.optionAttributes[0].name
            this.attribute = attr
        },
        setConfiguration () {
            if (this.configuration) {
                this.attribute = this.configuration.attribute
                this.filtersEnabled = this.configuration.filtersEnabled
                this.currentSel = this.configuration.currentSel
                if (this.configuration.attrType === 'numeric') {
                    this.timeUnit = this.configuration.options.timeUnit
                } else {
                    this.eventAttribute = this.configuration.options.eventAttribute
                }
            }
        },
        saveConfiguration () {
            const configuration = {
                attribute: this.attribute,
                filtersEnabled: this.filtersEnabled,
                currentSel: this.currentSel,
                attrType: this.numericAttribute ? 'numeric' : 'categorical',
                options: {
                    timeUnit: this.timeUnit,
                    eventAttribute: this.eventAttribute,
                },
            }
            this.$emit('update', { index: this.index, configuration })
        },
        handleResize () {
            if (this.$refs.chart) {
                setTimeout(() => {
                    this.$refs.chart.resize()
                }, 500)
            }
        },
        formatNumber (num) {
            switch (true) {
                case num >= 1000000:
                    return `${parseFloat((num / 1000000).toFixed(1))}M`
                case num >= 100000:
                    return `${(num / 1000).toFixed(0)}K`
                case num >= 1000:
                    return `${parseFloat((num / 1000).toFixed(1))}K`
                default:
                    return parseFloat(num.toFixed(1))
            }
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.range = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.range = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.range = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.range = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
        setSelected () {
            this.selected = !this.selected
        },
        handleClickOption (sel) {
            this.$emit('title', sel)
            this.setSelected()
        },
        handleActivitiesInput (selected) {
            if (!this.activities.includes(selected)) {
                this.activities.push(selected)
            } else {
                const index = this.activities.indexOf(selected)
                if (index !== -1) {
                    this.activities.splice(index, 1)
                }
            }
            this.selectedActivities = this.activities
            clearTimeout(this.activitiesTimer)
            this.activitiesTimer = setTimeout(() => {
                this.getData()
            }, 500)
        },
        handleStandardActivitiesInput () {
            this.selectedActivities = this.activities
            clearTimeout(this.activitiesTimer)
            this.activitiesTimer = setTimeout(() => {
                this.getData()
            }, 500)
        },
        getData () {
            this.saveConfiguration()
            this.$emit('chartLoading', true)
            this.resetChart()
            const typeAttr = this.optionAttributes.find(obj => obj.name === this.attribute)
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const data = {
                attribute: this.attribute,
                sortBy: this.currentSel.value,
                eventAttribute: this.eventAttribute,
                filters: this.filtersEnabled ? filters || [] : [],
                filterSets: this.filtersEnabled ? filterSetsUUIDs || [] : [],
                operator: generalOperator,
                timeUnit: this.timeUnit.unit,
                limit: 15,
                start: 0,
            }
            if (typeAttr.type === 'STRING' || typeAttr.type === 'BOOLEAN') {
                this.numericAttribute = false
            } else {
                this.numericAttribute = true
            }
            if (this.eventAttribute && !this.numericAttribute) {
                if (this.$refs.activitiesSelect) {
                    this.$refs.activitiesSelect.validate()
                    const valueInputError = this.$refs.activitiesSelect.hasError
                    if (valueInputError) {
                        this.emptyRows = false
                        return
                    }
                }
                data.activities = this.activities
            }
            apiRequest(Api().visualizations.attributePanel({ processId: this.processId, params: data }))
                .then((returnData) => {
                    if (returnData.rows.length === 0) {
                        this.emptyRows = true
                    } else {
                        this.emptyRows = false
                    }
                    this.updateChart(returnData)
                })
                .catch(() => {
                    notifyError()
                    this.resetChart()
                })
                .finally(() => {
                    this.$emit('chartLoading', false)
                })
        },
        getSplitFilteredActivities () {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const arcs = true
            const params = {
                filters,
                arcs,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }
            apiRequest(Api().visualizations.splitFilteredActivities({ processId: this.processId, params }))
                .then((data) => {
                    this.optionActivities = (data.allActivities).filter(item => !['START', 'END'].includes(item)).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    const acti = this.optionActivities[0]
                    this.activities = [acti]
                    this.selectedActivities = this.activities
                })
                .catch(notifyError)
        },
        resetChart () {
            this.barChartOptions.series[1].data = []
            this.barChartOptions.series[0].data = []
            this.barChartOptions.xAxis.data = []
        },
        updateChart (returnData) {
            if (returnData.data) {
                this.barChartOptions.yAxis[1].axisLabel = { show: false }
                this.barChartOptions.yAxis[0].axisLabel = { formatter: value => `${this.formatNumber(value)}` }
                this.barChartOptions.xAxis.axisLabel = { formatter: value => `${this.checkDate(value)}` }
                this.avg = this.App.numberLocationFormat(this.formatNumber(returnData.data.avg), false, true)
                this.max = this.App.numberLocationFormat(this.formatNumber(returnData.data.max), false, true)
                this.min = this.App.numberLocationFormat(this.formatNumber(returnData.data.min), false, true)
                this.sd = this.App.numberLocationFormat(this.formatNumber(returnData.data.sd), false, true)
                this.barChartOptions.tooltip = {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        // eslint-disable-next-line max-len
                        let tooltipContent = `<div><div style="width: 10px; height: 10px; background-color: ${params[0].color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                        tooltipContent += `${this.checkDate(params[0].name)}: ${(params[0].value).toFixed(2)} <br>`
                        tooltipContent += '</div>'
                        return tooltipContent
                    },
                }
            } else {
                this.barChartOptions.yAxis[1].axisLabel = { show: true }
                this.barChartOptions.yAxis[0].axisLabel = { formatter: value => `${this.formatNumber(value)}${this.range}` }
                this.barChartOptions.xAxis.axisLabel = {
                    formatter: (value) => {
                        const maxLength = 8
                        // eslint-disable-next-line no-restricted-globals
                        if (!isNaN(value) && typeof value === 'number') {
                            return this.formatNumber(value)
                        }
                        if (value.length > maxLength) {
                            return `${value.slice(0, maxLength)}...`
                        }
                        return `${value}`
                    },
                }
                this.barChartOptions.tooltip = {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div style="max-width: 15em;">'
                        tooltipContent += `${this.lineBreak(params[0].name)}`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            // eslint-disable-next-line max-len
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            if (seriesName === this.$t('visualization.customPlotPanels.duration')) {
                                tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })} <br>`
                            } else {
                                tooltipContent += `${seriesName}: ${value} <br>`
                            }
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                }
            }
            this.maxDur = this.rangeDuration(Math.max.apply(null, [returnData.rows.map(item => (item.duration))][0]))
            const durationArr = returnData.rows.map((item) => {
                if (this.numericAttribute) {
                    return item.value
                }
                return this.formatSeconds(item.duration, this.maxDur)
            })

            const frequencyArr = [returnData.rows.map(item => item.frequency)][0]
            const commonAttributes = [returnData.rows.map(item => item.attribute)][0]
            this.barChartOptions.series[1].data = frequencyArr
            this.barChartOptions.series[0].data = durationArr
            this.barChartOptions.xAxis.data = commonAttributes
        },
        lineBreak (inputString) {
            let resultado = ''
            for (let i = 0; i < inputString.length; i += 22) {
                const linea = inputString.substr(i, 22)
                resultado += `${linea }<br>`
            }
            return resultado
        },
        checkDate (value) {
            if (this.timeUnit.unit === 'day') {
                return moment(value).format('DD/MM/YY')
            }
            if (this.timeUnit.unit === 'month') {
                return moment(value).format('MM/YYYY')
            }
            return value
        },
    },
}
</script>
<style scoped lang="scss">
.GraphSelectContainer {
    width:100%;
    height: 375px;
    display:flex;
}

.ChartContainer {
    width: 70%;
    height: 100%;
    flex-grow: 1;
    background-color: #fbfbfb;
    .Chart {
        width: 95%;
        height: 95%;
    }
}

.OptionsContainer {
    width: 30%;
}

.attrSelect {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Field {
    width: 90%;
    &:deep(.q-field__control) {
            height: 50px;
            .q-field__control-container .q-field__native span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 34px;
            }
            .q-field__append {
                height: 50px;
            }
    }
}

</style>
