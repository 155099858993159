<template>
    <Modal
        :isOpen="isOpen"
        @close="closeModal"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                align="center"
                weight="bold"
                class="header-text"
            >
                {{ $t('visualization.filters.filterSets.title') }}
            </WText>
        </template>
        <template
            #body
        >
            <div class="body-content">
                <div class="Section">
                    <div class="TableButtons">
                        <div class="LeftButtons">
                            <QBtnDropdown
                                autoClose
                                noCaps
                                color="primary"
                                icon="filter_alt"
                                menuAnchor="bottom end"
                                :menuOffset="[65,0]"
                                :label="$t('visualization.filters.filterSets.config.filterSetsTable.load')"
                                @click.stop
                            >
                                <QList>
                                    <QItem
                                        clickable
                                        @click="addToActive"
                                    >
                                        <QItemSection>
                                            <QItemLabel>{{ $t('visualization.filters.filterSets.config.filterSetsTable.loadAsFilterSet') }}</QItemLabel>
                                        </QItemSection>
                                    </QItem>
                                    <QItem
                                        clickable
                                        @click="replaceFilterSets"
                                    >
                                        <QItemSection>
                                            <QItemLabel>{{ $t('visualization.filters.filterSets.config.filterSetsTable.loadAsFilters') }}</QItemLabel>
                                        </QItemSection>
                                    </QItem>
                                </QList>
                            </QBtnDropdown>
                        </div>
                        <div class="RightButtons">
                            <QBtn
                                icon="delete_outline"
                                color="primary"
                                noCaps
                                :label="$t('visualization.filters.filterSets.config.filterSetsTable.removeSelected')"
                                @click="removeFilterSets"
                            >
                                <QTooltip>
                                    {{ $t('visualization.filters.filterSets.config.filterSetsTable.removeSelected') }}
                                </QTooltip>
                            </QBtn>
                        </div>
                    </div>
                </div>
                <div class="TableContainer">
                    <QTable
                        ref="table"
                        v-model:selected="selected"
                        flat
                        outlined
                        bordered
                        virtualScroll
                        :rows="rows"
                        :columns="columns"
                        :loading="isLoading"
                        :rowsPerPageOptions="rowsPerPageOptions"
                        separator="none"
                        rowKey="uuid"
                        class="Table q-mt-sm"
                        tableHeaderClass="bg-grey-2"
                        selection="multiple"
                    >
                        <template #body-cell-name="props">
                            <QTd :props="props">
                                <span>
                                    {{ props.row.name }}
                                </span>
                            </QTd>
                        </template>
                        <template #body-cell-operator="props">
                            <QTd
                                :props="props"
                            >
                                <div class="OperatorCell">
                                    <QSelect
                                        v-model="props.row.operator"
                                        outlined
                                        class="Select"
                                        :options="operators"
                                        @update:modelValue="updateFilterSet(props.row)"
                                    />
                                </div>
                            </QTd>
                        </template>
                        <template #body-cell-filters="props">
                            <QTd
                                :props="props"
                                class="FiltersTd"
                            >
                                <div class="FiltersContainer t-0-5">
                                    <div
                                        v-for="(filter, index) in props.row.filters"
                                        :key="`${filter.uuid}-${index}`"
                                        class="FilterContainer"
                                    >
                                        <FilterIcon
                                            :id="getIconId(filter)"
                                            :height="30"
                                            :width="30"
                                        />
                                        <div class="FilterLabelContainer">
                                            <QTooltip
                                                contentClass="bg-primary text-uppercase"
                                                maxWidth="300px"
                                            >
                                                {{ formatLabelValue(filter).tooltip }}
                                            </QTooltip>
                                            <WText
                                                uppercase
                                                :size="12"
                                                :color="getColor(filter)"
                                            >
                                                {{ formatLabelValue(filter).label }}
                                            </WText>
                                            <WText
                                                :size="12"
                                                weight="bold"
                                                :color="getColor(filter)"
                                            >
                                                {{ formatLabelValue(filter).value }}
                                            </WText>
                                        </div>
                                    </div>
                                </div>
                            </QTd>
                        </template>
                    </QTable>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import {
    QBtnDropdown,
    QList,
    QItemSection,
    QItemLabel,
    QTooltip,
} from 'quasar'
import { filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import {
    ATTRIBUTTE_TYPES, ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, FILTERS_TYPES, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS,
} from '@/screens/Project/Process/Visualization/sections/FilterModal/filtersEntity'
import { Modal } from '@/components'
import FilterIcon from '../FilterModal/components/FilterIcon.vue'

const ROWS_PER_PAGE_OPTIONS = [
    5, 15, 30, 50, 75, 100,
]
export default {
    name: 'FilterSetsModal',
    components: {
        FilterIcon,
        Modal,
        QBtnDropdown,
        QList,
        QItemSection,
        QItemLabel,
        QTooltip,
    },
    mixins: [filtersStorageMixin],
    props: {
        activeFilters: VueTypes.array,
        datasetId: VueTypes.string,
        isOpen: VueTypes.bool.def(false),
    },
    data () {
        return {
            operator: '',
            showCreate: false,
            isLoading: false,
            filterSetName: '',
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            total: undefined,
            page: 1,
            rows: [],
            rowsPerPage: 15,
            selected: [],
            columns: [
                {
                    name: 'name', label: this.$t('visualization.filters.filterSets.config.filterSetsTable.filterName'), field: 'name', align: 'center',
                },
                {
                    name: 'operator', label: this.$t('visualization.filters.filterSets.config.filterSetsTable.operator'), field: 'operator', align: 'center',
                },
                {
                    name: 'filters', label: this.$t('visualization.filters.filterSets.config.filterSetsTable.filtersIncluded'), field: 'filters', align: 'center',
                },
            ],
        }
    },
    computed: {
        operators () {
            return [
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.AND'),
                    value: 'AND',
                },
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.OR'),
                    value: 'OR',
                },
            ]
        },
    },
    watch: {
        isOpen (open) {
            if (open) {
                this.resetFilterSetsModal()
                this.getDatasetFilters()
            }
        },
    },
    methods: {
        resetFilterSetsModal () {

        },
        getDatasetFilters () {
            this.isLoading = true
            apiRequest(Api().datasets.getDatasetFilters({ datasetId: this.datasetId }))
                .then(
                    ({ data }) => {
                        this.rows = data.map(set => ({
                            name: set.name,
                            operator: set.operator,
                            filters: set.filters,
                            uuid: set.uuid,
                        }))
                    },
                )
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        updateFilterSet (filterSet) {
            const { datasetId } = this
            const filterSetId = filterSet.uuid
            const params = { operator: filterSet.operator.value }
            apiRequest(Api().datasets.updateFilterSetOperator({ datasetId, filterSetId, params }))
                .then((res) => {
                    this.getDatasetFilters(datasetId)
                    const isActiveFilter = this.activeFilters.some(activeFilter => activeFilter.uuid === filterSetId || activeFilter.filterset_uuid === filterSetId)
                    if (isActiveFilter) {
                        this.$emit('repeatCalls')
                    }
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        addToActive () {
            this.$emit('addToActive', this.selected)
            this.closeModal()
        },
        replaceFilterSets () {
            this.$emit('replaceFilters', this.selected)
            this.closeModal()
        },
        removeFilterSets () {
            const uuids = this.selected.map(filterSet => filterSet.uuid)
            const filtersSets = { filters: uuids }
            apiRequest(Api().datasets.deleteMultipleFiltersSets({ datasetId: this.datasetId, filtersSets }))
                .then(() => {
                    notifySuccess(this.$t('visualization.filters.filterSets.delete.success'))
                    this.sendRemoveFilterSets(filtersSets)
                    this.getDatasetFilters()
                    this.selected = []
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        sendRemoveFilterSets (filterSets) {
            this.$emit('removedFilterSets', filterSets)
        },
        getIconId (filterSet) {
            return ATTRIBUTES_FILTERS.includes(filterSet.kind) ? filterSet.kind : filterSet.id
        },
        formatLabelValue (filter = {}) {
            if (ACTIVITY_FILTERS.includes(filter.kind) || ACTIVITY_FILTERS.includes(filter.id)) {
                const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (ARC_FILTERS.includes(filter.kind) || ARC_FILTERS.includes(filter.id)) {
                const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (DURATION_FILTERS.includes(filter.kind) || DURATION_FILTERS.includes(filter.id)) {
                let formattedValue = filter.kind
                    ? moment.duration(filter.value || filter.values[0]).format('d[D] h[h]:m[m]:s[s]')
                    : filter.value
                if (filter.id.includes('ACTIVITIES')) {
                    formattedValue = filter.kind
                        ? moment.duration(filter.values[filter.values.length - 1]).format('d[D] h[h]:m[m]:s[s]')
                        : filter.value
                    if (filter.id.endsWith('LESS_THAN')) {
                        formattedValue = `< ${formattedValue}`
                    } else if (filter.id.endsWith('GREATER_THAN')) {
                        formattedValue = `> ${formattedValue}`
                    }
                }
                const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)
                const additionalValue = (filter.type || {}).arc || filter.arc || (filter.type || {}).category || filter.activity ||
                      (filter.type && `${(filter.type || {}).activitySource}-${(filter.type || {}).activityTarget}`) || (filter.values && `${filter.values[0]}-${filter.values[1]}`)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    additionalValue,
                    tooltip: `${formattedLabel}: ${formattedValue}${additionalValue ? ` | ${additionalValue}` : ''}`,
                }
            } if (DATE_FILTERS.includes(filter.kind) || DATE_FILTERS.includes(filter.id)) {
                const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)
                const formattedValue = filter.value || filter.values[0]
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (ATTRIBUTES_FILTERS.includes(filter.kind) || ATTRIBUTES_FILTERS.includes(filter.id)) {
                const isBooleanType = ((filter.kind || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN) || (filter.id || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN))
                if (isBooleanType) {
                    if (filter.kind) {
                        const bool = ATTRIBUTTE_TYPES.BOOLEAN_ATTRIBUTE.find(a => a.value === filter.id) || {}
                        const formattedValue = typeof filter.attribute === 'string' ? `${filter.attribute} - ${bool.label}` : filter.label
                        return {
                            label: formattedValue,
                            value: '',
                            tooltip: formattedValue,
                        }
                    }
                    return {
                        label: filter.label,
                        value: '',
                        tooltip: filter.label,
                    }
                }

                const attributes = []
                Object.values(ATTRIBUTTE_TYPES).forEach((getTypes) => {
                    const types = getTypes()
                    types.forEach((type) => {
                        attributes.push(type)
                    })
                })
                const attributeFormatterLabel = filter.kind ? `${filter.attribute} - ${(attributes.find(a => a.value === filter.id)).label}` : filter.label
                const formattedValue = filter.value || filter.values[0]
                return {
                    label: attributeFormatterLabel,
                    value: formattedValue,
                    tooltip: `${attributeFormatterLabel}: ${formattedValue}`,
                }
            } if (VARIANT_FILTERS.includes(filter.kind) || VARIANT_FILTERS.includes(filter.id)) {
                const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)
                let formattedValue = ' '
                if (this.variants !== undefined) {
                    filter.value?.constructor === Array
                        ? formattedValue = filter.value.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            return variant ? variant.name : null
                        })
                        : formattedValue = filter.values.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            return variant ? variant.name : null
                        })
                    formattedValue = formattedValue.join(', ')
                }
                this.$root.$emit('hasVariantFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (LOOP_FILTERS.includes(filter.kind) || LOOP_FILTERS.includes(filter.id)) {
                const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)
                const formattedValue = filter.type.loopName
                this.$root.$emit('hasLoopFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            }
            return {
                label: filter.label,
                value: filter.value,
                tooltip: `${filter.label}: ${filter.value}`,
            }
        },
        getColor ({ pending, toDelete }) {
            if (toDelete) return 'red'
            if (pending) return 'gray03'
            return ''
        },
        closeModal () {
            this.$emit('close', false)
            this.resetModal()
        },
        resetModal () {
            this.selected = []
            this.filterSetName = ''
            this.showCreate = false
        },
    },
}
</script>
<style scoped lang="scss">
.Section {
  margin: 20px 0;
}
.MainTitle {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0 20px;
}
.Title {
    font-size: large;
    font-weight: bold;
}
.TableContainer {
    margin-bottom: 25px;
}
.FiltersTd {
    max-width: 530px;
}
.FiltersContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}
.FilterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}
.FilterLabelContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    .WText {
        width: auto;
        max-width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.BackArrow {
    right: 50px;
    position: absolute;
    top: 30px;
    color: $black;
    font-size: 24px;
    opacity: .65;
    cursor: pointer;
}
.Select {
    width: fit-content;
    min-width: 90px;
}
.OperatorCell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.TableButtons {
    display: flex;
}
.LeftButtons {
    width: 50%;
    display: flex;
    gap: 20px;
}
.RightButtons {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.ApplyButton {
    width: 10%;
    margin-left: 20px;
}
.body-content {
    margin-top: 2rem;

    @media (min-width: 768px) {
        margin-top: 4rem;
    }
}
.header-text {
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
        margin-bottom: 1.5rem;
    }
}
</style>
