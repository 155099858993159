<template>
    <QDialog
        v-model="dialog"
        persistent
        transitionShow="slide-up"
        transitionHide="slide-down"
    >
        <QCard class="ModelPreview">
            <QBar class="bg-primary text-white">
                <div>{{ name }}</div>
                <QSpace />
                <QBtn
                    dense
                    flat
                    icon="close"
                    @click="$emit('closePreview')"
                />
            </QBar>
            <QCardSection>
                <BpmnViewer
                    ref="bpmnViewer"
                    :data="xmlData"
                    :centerButton="true"
                />
            </QCardSection>
        </QCard>
    </QDialog>
</template>
<script>
import VueTypes from 'vue-types'
import BpmnViewer from '@/components/BpmnViewer/BpmnViewer.vue'

export default {
    name: 'ModelPreview',
    components: {
        BpmnViewer,
    },
    props: {
        show: VueTypes.bool,
        bpmnData: VueTypes.any,
    },
    emits: ['closePreview'],
    data () {
        return {
            dialog: false,
            name: undefined,
            xmlData: undefined,
        }
    },
    watch: {
        show () {
            this.dialog = this.show
        },
        bpmnData () {
            if (this.bpmnData !== undefined) {
                this.name = this.bpmnData.modelName
                this.xmlData = this.bpmnData.bpmn
            }
        },
    },
    methods: {
    },
}
</script>
<style scoped lang="scss">
    .ModelPreview {
        width: 900px;
        max-width: 1024px;
    }
    .ButtonRow {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
    }
</style>
