<template>
    <div>
        <div :class="['RowCard', getSeverityColor(row.severity)]">
            <QItemSection
                avatar
                class="Avatar"
            >
                <QAvatar
                    square
                    color="white"
                    font-size="30px"
                    :textColor="getSeverityColor(row.severity)"
                    :icon="getSeverityIcon(row.severity)"
                    class="text-white"
                />
            </QItemSection>
            <QItemSection class="Name">
                {{ row.name }}
            </QItemSection>
            <QItemSection class="Date">
                <span class="Bold">{{ $t('booster.alerts.lastDetected') + ' ' }}</span>
                <span>{{ formatDate(row.timestamp) }}</span>
            </QItemSection>
            <QItemSection class="ItemButtons">
                <q-btn
                    noCaps
                    class="RightButton"
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.details.label')"
                    @click="openNewAlert = true"
                />
                <QDialog
                    v-model="openNewAlert"
                    class="Dialog"
                >
                    <AlertDetails
                        :alert="row"
                        :processId="processId"
                        @closeEvaluationDetails="openNewAlert = false"
                    />
                </QDialog>
                <q-btn
                    noCaps
                    class="RightButton"
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.dismiss')"
                    @click="dismissAlert"
                />
            </QItemSection>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { isEmpty } from 'lodash'
/* eslint-disable */
import AlertDetails from './AlertDetails.vue'

export default {
    name: 'AlertElement',
    components: {
        AlertDetails,
    },
    inject: ['App'],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        row: VueTypes.any,
    },
    emits: ['filter', 'dismissAlert'],
    data () {
        return {
            openNewAlert: false,
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
        getSeverityIcon (severity) {
            const icons = {
                LOW: 'info',
                MEDIUM: 'warning',
                HIGH: 'error',
            }
            return icons[severity]
        },
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        withFilterSet (row) {
            return !isEmpty(row.filters)
        },
        formatDate (timestamp) {
            const date = new Date(timestamp)
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        },
        dismissAlert () {
            this.$emit('dismissAlert')
        }
    },
}
</script>

<style scoped lang="scss">
    .AlertList {
        height: fit-content;
        max-height: 90vh;
        overflow: auto;
    }

    .RowCard {
        margin: 1vw;
        padding: 1vw;
        display: flex;
        position: relative;
        background-color: var(--q-color-red); /* Placeholder, será reemplazado dinámicamente */
    }

    .amber-11 {
        background-color: #ffe99b;
    }

    .orange-11 {
        background-color: #ffd19b;
    }

    .red-11 {
        background-color: #fb9191;
    }

    .Avatar {
        width: 10%;
    }

    .Name {
        font-weight: bold;
        width: 10%;
    }

    .Date {
        width: 20%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        flex-direction: row;
    }

    .Bold {
        font-weight: bold;
    }

    .ItemButtons {
        display: flex; /* Siempre se reserva el espacio */
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        opacity: 0; /* Oculta visualmente el contenido */
        visibility: hidden; /* No interactuable */
        transition: opacity 0.3s ease; /* Animación suave al aparecer */
    }

    .RowCard:hover .ItemButtons {
        opacity: 1; /* Muestra el contenido */
        visibility: visible; /* Ahora interactuable */
    }

    .RightButton {
        margin-left: 2px;
        margin-right: 2px;
        width: 40%;
    }
    .q-dialog__inner--minimized > div {
        min-width: 80%;
    }
</style>
