<template>
    <div>
        <q-card
            flat
            bordered
            class="q-mb-sm q-pa-md"
        >
            <div
                class="q-pa-md flex row FormContainer"
            >
                <div
                    class="flex row"
                >
                    <WText
                        class="q-mb-sm"
                        :size="18"
                    >
                        {{ $t('booster.alerts.definition.createScreen.newAlert') }}
                    </WText>
                </div>
                <QIcon
                    class="Cancel"
                    name="close"
                    size="20px"
                    @click="cancel"
                />
            </div>
            <q-card
                flat
                class="q-mb-sm q-pa-md"
            >
                <div
                    class="row items-center q-pa-xs"
                >
                    <div
                        class="Box q-pa-md"
                        style="width: 100%;"
                    >
                        <div
                            class="flex row"
                        >
                            <div>
                                <WText>{{ $t('booster.alerts.definition.name') }}</WText>
                                <QInput
                                    ref="name"
                                    v-model="nameFld"
                                    outlined
                                    dense
                                    class="q-mt-sm"
                                    lazyRules
                                    stackLabel
                                    maxlength="100"
                                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.name') })]"
                                />
                            </div>
                            <div class="SeverityContainer">
                                <WText>{{ $t('booster.alerts.definition.severity') }} </WText>
                                <QBtnToggle
                                    v-model="severityTgl"
                                    :toggleColor="getSeverityColor(severityTgl)"
                                    toggleTextColor="black"
                                    push
                                    unelevated
                                    noCaps
                                    :options="severityOptions"
                                    class="q-my-sm"
                                />
                            </div>
                        </div>
                        <div
                            class="col-sm-12"
                        >
                            <AlertTriggers
                                @update:triggers="updateTriggers"
                            />
                        </div>
                    </div>
                </div>
                <div class="q-pa-xs">
                    <AlertsNotifications
                        @updateSendData="updateSendData"
                    />
                </div>
                <div class="flex q-gutter-md justify-end q-pa-md">
                    <QBtn
                        :label="$t('booster.alerts.definition.createScreen.cancel')"
                        color="primary"
                        noCaps
                        outline
                        @click="cancel"
                    />
                    <QBtn
                        :label="$t('booster.alerts.definition.createScreen.addAlert')"
                        color="primary"
                        noCaps
                        :disable="!saveBtnStatus"
                        @click="create"
                    />
                </div>
            </q-card>
        </q-card>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { validatorsMixin, filtersStorageMixin } from '@/mixins'
import {
    notifyError, notifySuccess,
    Api, apiRequest,
} from '@/api'
import AlertsNotifications from './AlertsNotifications.vue'
import AlertTriggers from './AlertTriggers.vue'

const DEFAULT_SEVERITY = 'LOW'

export default {
    name: 'NewAlert',
    components: {
        AlertsNotifications,
        AlertTriggers,
    },
    mixins: [validatorsMixin, filtersStorageMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        attributes: VueTypes.oneOfType([VueTypes.array, VueTypes.object]),
        selectedSeverityNode: VueTypes.string,
    },
    emits: ['createdAlert', 'cancel'],
    data () {
        return {
            nameFld: undefined,
            severityTgl: DEFAULT_SEVERITY,
            notifications: [],
            saveBtnStatus: true,
            unit: {
                value: 'minutes',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            triggers: {},
        }
    },
    computed: {
        severityOptions () {
            return [{
                value: 'LOW',
                label: this.$t('booster.alerts.detected.severityRiskLabels.LOW'),
            }, {
                value: 'MEDIUM',
                label: this.$t('booster.alerts.detected.severityRiskLabels.MEDIUM'),
            }, {
                value: 'HIGH',
                label: this.$t('booster.alerts.detected.severityRiskLabels.HIGH'),
            }]
        },
    },
    created () {
        if (this.selectedSeverityNode) this.severityTgl = this.selectedSeverityNode
    },
    mounted () {},
    methods: {
        updateSendData (data) {
            this.notifications = data
        },
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        cancel () {
            this.$emit('cancel')
        },
        hasValidationErrors () {
            if (this.notifications.mailToCreatingUser === false) {
                if (this.notifications.mailToNotify) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return emailRegex.test(this.notifications.mailToNotify)
                }
                return !(this.notifications.mailToNotify === '' || this.notifications.mailToNotify === null)
            }
            return !(this.nameFld === undefined || this.nameFld === '' || Object.keys(this.triggers).length === 0)
        },
        create () {
            if (this.hasValidationErrors()) {
                this.saveBtnStatus = false
                const params = {
                    name: this.nameFld,
                    severity: this.severityTgl,
                    mailToCreatingUser: this.notifications.mailToCreatingUser,
                    mailToNotify: this.notifications.mailToNotify ? this.notifications.mailToNotify : null,
                    frequencyNotification: this.notifications.frequencyNotification ? this.notifications.frequencyNotification : null,
                    weekDayNotification: this.notifications.weekDayNotification ? this.notifications.weekDayNotification : null,
                    timeNotification: this.notifications.timeNotification ? this.notifications.timeNotification : null,
                    filters: this.triggers,
                }
                const datasetId = this.processId
                apiRequest(Api().alerts.create({ datasetId, params }))
                    .then(() => {
                        this.$emit('createdAlert')
                        notifySuccess(this.$t('booster.alerts.notifications.created'))
                    })
                    .catch(notifyError)
                    .finally(() => (
                        this.cancel()
                    ))
            } else {
                notifyError({ message: this.$t('booster.alerts.notifications.validateError') })
            }
        },
        updateTriggers (triggers) {
            this.triggers = triggers
        },
    },
}
</script>
<style scoped lang="scss">
.Box {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
.Cancel {
    cursor:pointer;
}
.FormContainer {
    justify-content: space-between;
}
.SeverityContainer {
    margin-left: 5vw;
}
</style>
