<template>
    <div>
        <Transition
            name="fade"
            mode="out-in"
        >
            <q-inner-loading :showing="!preview || isLoadingRelevant" />
        </Transition>
        <div
            v-if="preview"
            class="Step"
        >
            <Preview
                v-bind="preview"
                :modelValue="modelValue.config"
                :loading="isLoading || isLoadingRelevant"
                @update:modelValue="handleInput"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import Preview from './Preview/Preview.vue'

const PREVIEW_LENGTH = 10
const RELEVANT_ATTRIBUTES_LIMIT = 3

const normalizeCols = (cols = [], traceIdValue) => cols.map(col => ({
    align: 'left',
    field: col.name,
    label: col.name,
    name: col.name,
    type: col.name === traceIdValue ? 'STRING' : col.type,
}))

const normalizeRows = (rows = []) => rows.map((data, index) => ({ ...data.row, index }))

export default {
    name: 'StepRelevantFields',
    components: {
        Preview,
    },
    props: {
        datasetId: VueTypes.string,
        projectId: VueTypes.string,
        modelValue: VueTypes.shape({
            config: VueTypes.any.isRequired,
        }).loose,
        isLoadingRelevant: VueTypes.bool.def(false),
    },
    emits: ['update:modelValue'],
    data () {
        return {
            preview: undefined,
            isLoading: false,
        }
    },
    mounted () {
        this.getPreview()
    },
    methods: {
        isValid () {
            const { modifiedTypes } = this.modelValue?.config || {}
            if (!modifiedTypes) {
                return {
                    checkStringType: true,
                    checkCountRelevant: true,
                }
            }

            const relevantAtt = modifiedTypes.filter(c => c.relevant === true)
            const relevantCount = relevantAtt?.length
            const checkStringType = relevantAtt.some(a => a.type !== 'STRING')
            const checkCountRelevant = relevantCount <= RELEVANT_ATTRIBUTES_LIMIT
            return {
                checkStringType: !checkStringType,
                checkCountRelevant,
            }
        },
        handleInput (config) {
            this.$emit('update:modelValue', {
                ...this.modelValue,
                config,
            })
        },
        async getPreview () {
            const {
                traceId,
                activity,
                start,
                end,
            } = this.modelValue?.config || {}
            const keyFields = [traceId.value, activity.value, start.value]
            if (end) { keyFields.push(end.value) }
            const params = { length: PREVIEW_LENGTH }
            this.isLoading = true
            const { datasetId } = this
            if (datasetId) {
                apiRequest(Api().datasets.preview({ datasetId, params }))
                    .then((preview) => {
                        const { columns, rows } = preview || {}
                        this.preview = {
                            // columns: normalizeCols(columns.filter(col => !keyFields.includes(col.name))),
                            columns: normalizeCols(columns, traceId.value),
                            rawColumns: columns,
                            rows: normalizeRows(rows),
                        }
                        this.$emit('update:modelValue', { ...this.modelValue, preview })
                    })
                    .catch((error) => {
                        notifyError(error)
                    })
                    .finally(() => (this.isLoading = false))
            }
        },
    },
}
</script>
