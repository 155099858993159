<template>
    <div class="AlertList">
        <q-inner-loading :showing="isLoading" />
        <div
            v-if="!isLoading"
            class="row q-mb-sm q-space-around"
        >
            <div v-if="!selectedNode?.id && !selectedSeverityNode">
                <h5 class="Title">
                    {{ $t('booster.alerts.all') + ' ('+ total + ')' }}
                </h5>
            </div>
            <div
                v-else
            >
                <h5 class="Title">
                    {{ (selectedNode.name || translatedSeverityNode) + ' (' + total + ')' }}
                </h5>
            </div>
            <!-- ALL EVALUATIONS -->
            <div
                v-if="!selectedNode?.id && !selectedSeverityNode"
                class="TitleButtons"
            >
                <q-btn
                    noCaps
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.newAlert')"
                    @click="openNewAlert = true"
                />
                <q-btn
                    noCaps
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.dismissAll')"
                    @click="dismissAll()"
                />
            </div>
            <!-- SEVERITY EVALUATIONS -->
            <div
                v-if="!selectedNode?.id && selectedSeverityNode"
                class="TitleButtons"
            >
                <q-btn
                    noCaps
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.newAlert')"
                    @click="openNewAlert = true"
                />
                <q-btn
                    noCaps
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.dismissAll')"
                    @click="dismissAll()"
                />
            </div>
            <!-- ALERT EVALUATIONS -->
            <div
                v-if="selectedNode?.id && !selectedSeverityNode"
                class="TitleButtons"
            >
                <q-btn
                    noCaps
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.definition.configureAlert')"
                    @click="openConfigAlert = true"
                />
                <q-btn
                    noCaps
                    color="primary"
                    textColor="white"
                    :label="$t('booster.alerts.dismissAll')"
                    @click="dismissAll()"
                />
                <q-btn
                    color="red"
                    noCaps
                    textColor="white"
                    :label="$t('booster.alerts.definition.removeAlert')"
                    @click="removeAlert"
                />
            </div>
            <QDialog
                v-model="openNewAlert"
                class="Dialog"
            >
                <NewAlert
                    :processId="processId"
                    :attributes="attributes"
                    :selectedSeverityNode="selectedSeverityNode"
                    @cancel="openNewAlert = false"
                    @createdAlert="onCreatedAlert"
                />
            </QDialog>
            <QDialog
                v-model="openConfigAlert"
                class="Dialog"
            >
                <ConfigureAlert
                    :processId="processId"
                    :selectedNode="selectedNode"
                    :selectedSeverityNode="selectedSeverityNode"
                    @updatedAlert="onUpdatedAlert"
                    @cancel="openConfigAlert = false"
                />
            </QDialog>
        </div>
        <Table
            ref="table"
            class="EvaluationsTable"
            :data="normalizeRows"
            :total="total"
            :loading="isLoading"
            hideHeader
            rowKey="uuid"
            @tableRequest="fetchData"
        >
            <template #content="{ row }">
                <AlertElement
                    :row="row"
                    :processId="processId"
                    @dismissAlert="dismissAlert(row)"
                />
            </template>
            <template #empty>
                <EmptyList
                    class="mt-2 mb-2"
                    :title="emptyMessage"
                />
            </template>
        </Table>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { Table, EmptyList } from '@/components'
import AlertElement from './AlertElement.vue'
import NewAlert from './NewAlert.vue'
import ConfigureAlert from './ConfigureAlert.vue'

export default {
    name: 'AlertList',
    components: {
        AlertElement,
        NewAlert,
        ConfigureAlert,
        Table,
        EmptyList,
    },
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        showDismissed: VueTypes.bool,
        filter: VueTypes.string,
        attributes: VueTypes.oneOfType([VueTypes.array, VueTypes.object]),
        selectedNode: VueTypes.object,
        selectedSeverityNode: VueTypes.string,
    },
    emits: ['dismissedAlert', 'dismissedAllAlerts', 'createdAlert', 'removedAlert'],
    data () {
        return {
            alerts: [],
            isLoading: true,
            total: 0,
            filterByType: null,
            openNewAlert: false,
            openConfigAlert: false,
        }
    },
    computed: {
        normalizeRows () {
            const severityOrder = { HIGH: 1, MEDIUM: 2, LOW: 3 }
            const sortedAlerts = (this.alerts || []).map(row => ({ ...row }))
                .sort((a, b) => severityOrder[a.severity] - severityOrder[b.severity])
            return sortedAlerts
        },
        translatedSeverityNode () {
            const severityMap = {
                HIGH: this.$t('booster.alerts.severity.high'),
                MEDIUM: this.$t('booster.alerts.severity.medium'),
                LOW: this.$t('booster.alerts.severity.low'),
            }
            return severityMap[this.selectedSeverityNode] || this.selectedSeverityNode
        },
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return {
                ...(this.filters || {}),
                ...pagination,
            }
        },
        emptyMessage () {
            return this.$t('booster.alerts.detected.noAlertEvaluations')
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        isArchived ({ deletedAt }) {
            return !!deletedAt
        },
        async dismissAlert (evaluationRow) {
            const datasetId = this.processId
            const params = {
                alertUuid: evaluationRow.uuid,
                alertEvalDate: evaluationRow.timestamp,
            }
            apiRequest(Api().alerts.dismiss({ datasetId, params }))
                .then(() => {
                    this.fetchData()
                    this.$emit('dismissedAlert')
                    notifySuccess(this.$t('booster.alerts.notifications.dismissSuccess'))
                })
                .catch(notifyError)
        },
        async dismissAll () {
            const datasetId = this.processId
            const params = {
                alertUuid: this.selectedNode.id,
                alertSeverity: this.selectedSeverityNode,
            }
            apiRequest(Api().alerts.dismissAll({ datasetId, params }))
                .then(() => {
                    this.fetchData()
                    this.$emit('dismissedAllAlerts')
                    notifySuccess(this.$t('booster.alerts.notifications.dismissAllSuccess'))
                })
                .catch(notifyError)
        },
        async fetchData () {
            if (!this.processId) {
                this.isLoading = false
            } else {
                const datasetId = this.processId
                const { page, rowsPerPage } = this.params
                console.log('fetchDataParams ', this.params)
                const params = {
                    search: null,
                    alertSeverity: this.selectedSeverityNode ? this.selectedSeverityNode : null,
                    filterType: null,
                    ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                    ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                    polling: false,
                    dismissed: this.showDismissed,
                    alertUuid: this.selectedNode ? this.selectedNode.id : null,
                }
                apiRequest(Api().alerts.evaluations({ datasetId, params }))
                    .then(({ data, total }) => {
                        this.alerts = Object.values(data).flat()
                        this.total = total
                        this.isLoading = false
                    })
                    .catch(notifyError)
            }
        },
        onUpdatedAlert () {
            this.fetchData()
        },
        onCreatedAlert () {
            this.fetchData()
            this.$emit('createdAlert', this.selectedSeverityNode)
        },
        async removeAlert () {
            const datasetId = this.processId
            const alertId = this.selectedNode.id
            const treeAlertId = `${this.selectedNode.id}_${this.selectedNode.name}`
            apiRequest(Api().alerts.delete({ datasetId, alertId }))
                .then(() => {
                    this.fetchData()
                    this.$emit('removedAlert', treeAlertId)
                    notifySuccess(this.$t('booster.alerts.notifications.removeSuccess'))
                })
                .catch(notifyError)
        },
    },
}
</script>
<style scoped lang="scss">
.Title {
    color: $primary;
    width: 100%;
}
.TitleButtons {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 5px;
}
.AlertList {
    height: fit-content;
    max-height: 80vh;
    overflow: hidden;
}
.EvaluationsTable {
    overflow: auto;
    height: 60vh;
}
.q-dialog__inner--minimized > div {
    min-width: 80%;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
}
.custom-select {
    width: 200px;
}
</style>
