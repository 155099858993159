<template>
    <div class="AlertsNotificationsContainer q-pa-md">
        <div class="NotifyAdress">
            <div class="AlignLabel">
                <div> {{ $t('booster.alerts.definition.createScreen.sendEmail') }}</div>
                <QBtnToggle
                    v-model="sendTgl"
                    toggleColor="primary"
                    unelevated
                    noCaps
                    push
                    dense
                    lazyRules
                    class="items-center q-mt-sm"
                    :options="sendOptions"
                />
            </div>
            <div
                v-if="sendTgl === 'other'"
                class="AlignLabel"
            >
                <div> {{ $t('booster.alerts.definition.createScreen.notifyTo') }}</div>
                <QInput
                    ref="mail"
                    v-model="emailAdress"
                    dense
                    outlined
                    stackLabel
                    maxlength="100"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.createScreen.notifyTo') })]"
                />
            </div>
        </div>
        <div
            v-if="sendTgl !== 'notSend'"
            class="NotifyDate"
        >
            <div class="AlignLabel">
                <div> {{ $t('booster.alerts.definition.createScreen.notificationFrequency.label') }}</div>
                <QBtnToggle
                    v-model="frequency"
                    toggleColor="primary"
                    push
                    unelevated
                    noCaps
                    dense
                    lazyRules
                    :options="frequencyOptions"
                    class="q-mt-sm"
                />
            </div>
            <div
                v-if="frequency==='WEEKLY'"
                class="AlignLabel"
            >
                <div> {{ $t('booster.alerts.definition.createScreen.notificationFrequency.label') }}</div>
                <QBtnToggle
                    v-model="day"
                    toggleColor="primary"
                    push
                    unelevated
                    noCaps
                    dense
                    lazyRules
                    :options="dayOptions"
                    class="q-mt-sm"
                />
            </div>
            <div
                v-if="frequency!=='UPDATE'"
                class="AlignLabel"
            >
                <div> {{ $t('booster.alerts.definition.createScreen.notificationFrequency.label') }}</div>
                <QSelect
                    v-model="selectedTime"
                    :options="timeOptions"
                    outlined
                    dense
                    class="Time"
                />
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'

const DEFAULT_SEND = 'user'
const DEFAULT_FREQUENCY = 'UPDATE'
const DEFAULT_DAY = 'MONDAY'
const DEFAULT_TIME = '12:00'
export default {
    name: 'AlertsNotifications',
    components: {
    },
    mixins: [validatorsMixin],
    props: {
        currentData: VueTypes.object,
    },
    emits: ['updateSendData'],
    data () {
        return {
            sendTgl: DEFAULT_SEND,
            frequency: DEFAULT_FREQUENCY,
            day: DEFAULT_DAY,
            selectedTime: DEFAULT_TIME,
            timeOptions: this.generateTimeOptions(),
            emailAdress: undefined,
        }
    },
    computed: {
        sendOptions () {
            return [{
                value: 'user',
                label: this.$t('booster.alerts.definition.createScreen.user'),
            },
            {
                value: 'other',
                label: this.$t('booster.alerts.definition.createScreen.other'),
            },
            {
                value: 'notSend',
                label: this.$t('booster.alerts.definition.createScreen.notSend'),
            }]
        },
        frequencyOptions () {
            return [{
                value: 'UPDATE',
                label: this.$t('booster.alerts.definition.createScreen.notificationFrequency.everyUpdate'),
            },
            {
                value: 'DAILY',
                label: this.$t('booster.alerts.definition.createScreen.notificationFrequency.daily'),
            },
            {
                value: 'WEEKLY',
                label: this.$t('booster.alerts.definition.createScreen.notificationFrequency.weekly'),
            }]
        },
        dayOptions () {
            return [
                {
                    value: 'MONDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.monday'),
                },
                {
                    value: 'TUESDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.tuesday'),
                },
                {
                    value: 'WEDNESDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.wednesday'),
                },
                {
                    value: 'THURSDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.thursday'),
                },
                {
                    value: 'FRIDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.friday'),
                },
                {
                    value: 'SATURDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.saturday'),
                },
                {
                    value: 'SUNDAY',
                    label: this.$t('booster.alerts.definition.createScreen.notificationDay.sunday'),
                },
            ]
        },
    },
    watch: {
        sendTgl (newVal) {
            if (newVal !== 'other') {
                this.emailAdress = null
            }
            this.emitData()
        },
        emailAdress: 'emitData',
        frequency (newVal) {
            if (newVal !== 'WEEKLY') {
                this.day = DEFAULT_DAY
            }
            this.emitData()
        },
        day: 'emitData',
        selectedTime: 'emitData',
        currentData: {
            immediate: true,
            handler (currentData) {
                if (currentData?.sendTgl === true) {
                    this.sendTgl = 'user'
                } else if (currentData.sendTgl === false) {
                    this.sendTgl = 'other'
                } else {
                    this.sendTgl = 'notSend'
                }
                this.frequency = currentData?.frequency || DEFAULT_FREQUENCY
                this.day = currentData?.day || DEFAULT_DAY
                this.selectedTime = (currentData.time !== null && currentData.time !== undefined)
                    ? currentData.time
                    : DEFAULT_TIME
                this.emailAdress = currentData.emailAdrs
            },
        },
    },
    mounted () {
        this.emitData()
    },
    methods: {
        emitData () {
            const data = {
                // eslint-disable-next-line no-nested-ternary
                mailToCreatingUser: (this.sendTgl === 'user' ? true : (this.sendTgl === 'other' ? false : null)),
                frequencyNotification: this.frequency,
            }
            if (this.sendTgl === 'other') {
                data.mailToNotify = this.emailAdress
            }
            if (this.frequency === 'WEEKLY') {
                data.weekDayNotification = this.day
            }
            if (this.frequency !== 'UPDATE') {
                data.timeNotification = this.selectedTime
            }
            if (data.mailToCreatingUser === null) {
                data.frequencyNotification = null
                data.weekDayNotification = null
                data.timeNotification = null
            }
            this.$emit('updateSendData', data)
        },
        generateTimeOptions () {
            return Array.from({ length: 48 }, (_, i) => {
                const hours = String(Math.floor(i / 2)).padStart(2, '0')
                const minutes = i % 2 === 0 ? '00' : '30'
                return `${hours}:${minutes}`
            })
        },
    },
}
</script>
<style scoped lang="scss">
.AlertsNotificationsContainer{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
.NotifyAdress, .NotifyDate {
    display: flex;
    flex-direction: row;
}
QField {
    margin-right: 5vw;
}
.AlignLabel {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.Time {
    min-width:30px;
}
</style>
