export default {
    visualizationToBooster: 'Process booster',
    boosterDisabledTooltip: 'Debe configurar las condiciones necesarias para acceder al Booster',
    customAnalysis: 'Análisis personalizado',
    performanceBoosterTitle: 'Performance booster',
    overview: {
        tab: 'Vista general',
        description: 'Descubre cómo se comporta tu proceso',
        relevantNotDefined: 'Configure los atributos relevantes para mostrar los datos',
        slaNotDefined: 'Configure las condiciones de duración objetivo para visualizar los datos.',
        relevantAndSLANotDefined: 'Configure los atributos relevantes y las condiciones de duración objetivo para mostrar los datos.',
        caseCompletionNotDefined: 'Configure las condiciones de completitud de caso para mostrar los datos.',
        relevantAndCaseNotDefined: 'Configure los atributos relevantes y las condiciones de completitud de caso para mostrar los datos.',
        slaAndCaseNotDefined: 'Configure las condiciones de duración objetivo y las condiciones de completitud de caso para mostrar los datos.',
        relevantAndSLAAndCaseNotDefined: 'Configure los atributos relevantes, las condiciones de duración objetivo y las condiciones de completitud de' +
            ' caso para mostrar los datos.',
        costNotDefined: 'Configure las condiciones de coste para visualizar los datos.',
        relevantAndCostNotDefined: 'Configure los atributos relevantes y las condiciones de coste para mostrar los datos.',
        slaAndCostNotDefined: 'Configure las condiciones de duración objetivo y las condiciones de coste para mostrar los datos.',
        relevantAndSLAAndCostNotDefined: 'Configure los atributos relevantes, las condiciones de duración objetivo y las condiciones de coste para mostrar los datos.',
        noConditionsText: 'Puede definir las condiciones necesarias en los ajustes del dataset.',
    },
    state: {
        performanceBy: 'Rendimiento por ',
        rightClick: 'Clic derecho en una barra para mostrar grafo',
        leadTimesBy: 'Duración media por ',
        timeSpentPerCase: 'Tiempo medio invertido por caso',
        performanceComparison: 'comparación de rendimiento',
        leadTimes: ' duración media',
        cases: ' casos',
        selectFilteringElements: 'Elementos seleccionados para filtrado de recursos',
        evolutionOverTime: 'Evolución del rendimiento',
        overallPerformance: 'Rendimiento general por:',
        activity: 'Actividad',
        transition: 'Transición',
        variant: 'Variante',
        months: 'meses',
        weeks: 'semanas',
        all: 'Todos',
        below: 'Cumple duración objetivo',
        intermediate: 'Intermedio',
        above: 'Incumple duración objetivo',
        slaCompliance: 'Cumple duración objetivo',
        noTraces: 'No hay  trazas para los filtros actuales',
        performanceByClick: 'Haz clic en una barra del gráfico de la izquierda para seleccionar',
    },
    prediction: {
        tab: 'Predicción',
        description: 'Descubre cómo se comportará tu proceso',
        predictionHighlights: {
            name: 'Aspectos destacados de la predicción',
            cases: 'casos',
            ongoingCases: 'casos en curso ',
            meetingSLA: 'en condiciones de ',
            meetingSLAColor: 'cumplir duración objetivo',
            missingSLAColor: 'en riesgo ',
            missingSLA: 'de incumplir duración objetivo',
            exceeding: '',
            exceedingColor: 'excediendo duración objetivo',
        },
        predictedPerformance: {
            name: 'Rendimiento previsto por ',
            risk: 'Estimación del riesgo de predicciones en curso',
            riskLabel: 'Riesgo',
            duration: 'Duración predicha versus duración pasada',
            durationLabel: 'Dur. d.o.',
            distribution: 'Distribución del rendimiento de la duración objetivo',
            distributionSla: 'Distr. d.o.',
            distributionLabel: 'Distr. d.o.',
            past: 'Pasado',
            predicted: 'Predicción',
            finishedAbove: 'Finalizadas que incumplieron la duración objetivo',
            finishedUnder: 'Finalizadas que cumplieron la duración objetivo',
            finished: 'Finalizadas',
            ongoingAbove: 'En curso que incumplen la duración objetivo',
            ongoingUnder: 'En curso que cumplen la duración objetivo',
            finishedOngoing: 'Finalizadas - En curso',
            pastPredicted: 'Pasado - Predicción',
            ongoing: 'En curso',
            pastMonths: 'Pasados ',
            months: ' meses',
        },
        ongoingCases: {
            showOnly: 'Filtrar por ',
            ongoingCaseList: 'Lista de casos en curso',
            state: 'Estado',
            caseId: 'ID de caso',
            currentDuration: 'Duración actual',
            estimatedDuration: 'Duración estimada',
            Good: 'Bueno',
            Risk: 'En riesgo',
            above: 'Por encima de la duración objetivo',
            select: 'Por favor, seleccione un valor',
        },
        timeToMeet: {
            timeToMeet: 'Tiempo de cumplimiento ',
            improvementRate: 'Tasa de mejora mensual para alcanzar el ',
            improvementRateSla: 'Tasa de mejora mensual para alcanzar la ',
            improvementRateSecondPart: ' en ',
            improvementRateSlaLabel: 'duración objetivo',
            past: 'Pasados ',
            months: ' meses',
            bestPerforming: 'mejor rendimiento actual ',
        },
        analysisReportUnavailable:
            'El informe de los insights automáticos de predicción no se ha podido generar ' +
            'debido a que uno o varios de los atributos relevantes seleccionados no se encuentran ' +
            'asociados a los casos actualmente en ejecución. Pruebe cambiando los atributos relevantes ' +
            'en la configuración.',
    },
    improvement: {
        tab: 'Simulación',
        description: 'Descubre cómo mejorar tu proceso',
        activitiesImprove: 'Mejora de actividades de proceso',
        transitionsImprove: 'Mejora de transiciones de proceso',
        improvementResults: 'Resultados de mejora en ',
        improvementTables: {
            rule1: 'Solo números',
            rule2: '0-100',
            name: 'Nombre',
            currentImpact: 'Impacto actual',
            improvement: 'Mejora',
            estimatedImpact: 'Impacto estimado',
        },
        improvementFigures: {
            title: 'Cifras de mejora',
            cases: ' casos',
            red: 'rojo',
            green: 'verde',
            become: ' que se convierte en ',
            decrease: 'disminuye',
            overallDuration: ' en duración total',
            overallCosts: ' en gastos generales',
            days: ' días',
            hours: 'horas',
            seconds: 'seg',
            currentPerformance: 'Performance actual',
            improvedPerformance: 'Performance mejorada',
            slaCompliance: 'Cumple duración objetivo',
            slaNonCompliance: 'No cumple duración objetivo',
        },
        improvementScope: {
            title: 'Ámbito de la mejora',
            add: 'Seleccione y agregue pares atributo-valor para limitar el ámbito de la mejora',
            attributes: 'Atributos',
            values: 'Valores',
        },
    },
    tabs: {
        overview: 'Vista general',
        prediction: 'Predicción',
        improvement: 'Mejora',
    },
    d2t: {
        viewReport: 'Ver informe',
        analysisReport: 'Informe de análisis',
        copyToClipboard: 'Copiar al portapapeles',
        copied: '¡Copiado!',
    },
    alerts: {
        active: 'Alertas activas',
        all: 'Todas las alertas',
        severity: {
            high: 'Alta severidad',
            medium: 'Severidad media',
            low: 'Baja severidad',
        },
        tab: 'Alertas',
        description: 'Define y revisa alertas sobre el proceso',
        filterBy: 'Filtrar por',
        newAlert: 'Crear nueva alerta',
        lastDetected: 'Última detección: ',
        details: 'Detalles',
        dismiss: 'Descartar',
        dismissAll: 'Descartar todas',
        detected: {
            noAlertEvaluations: 'No hay evaluaciones de alertas para mostrar',
            lastDetected: 'Última detección',
            severityRiskLabels: {
                LOW: 'Bajo',
                MEDIUM: 'Medio',
                HIGH: 'Alto',
            },
            predictionRiskLabels: {
                LOW: 'Duración objetivo en riesgo',
                MEDIUM: 'Duración objetivo en riesgo',
                HIGH: 'Duración objetivo excedida',
            },
        },
        definition: {
            create: 'Nueva definición',
            configureAlert: 'Configurar alerta',
            removeAlert: 'Eliminar alerta',
            remove: 'Borrar seleccionadas',
            name: 'Nombre',
            severity: 'Severidad',
            types: {
                ALL: 'Todos',
                STANDARD: 'Standard',
                PREDICTION: 'Predicción',
                TIME: 'Temporal',
            },
            period: 'Periodo',
            record: 'registro',
            selected: 'de',
            createScreen: {
                newAlert: 'Nueva definición de alerta',
                sendEmail: 'Enviar notificación a',
                user: 'e-mail del usuario',
                other: 'otro e-mail',
                notSend: 'no enviar',
                notifyTo: 'E-mail de destino',
                cancel: 'Cancelar',
                addAlert: 'Añadir alerta',
                updateAlert: 'Modificar alerta',
                notificationFrequency: {
                    label: 'Frecuencia de notificación',
                    everyUpdate: 'con cada actualización',
                    daily: 'diariamente',
                    weekly: 'semanalmente',
                },
                notificationDay: {
                    label: 'Día de notificación',
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mié',
                    thursday: 'Jue',
                    friday: 'Vie',
                    saturday: 'Sáb',
                    sunday: 'Dom',
                },
                triggerConditions: {
                    label: 'Condiciones del disparador',
                    selectFilter: 'Filtro',
                    selectActivities: 'Actividades',
                    selectActivity: 'Actividad',
                    selectAttribute: 'Atributo',
                    constraints: 'Restricciones',
                    selectArcs: 'Arcos',
                    selectValue: 'Valor',
                    selectDate: 'Fecha',
                    selectUnit: 'Unidad',
                    selectTime: 'Hora',
                    noAttributes: 'No existen atributos de este tipo',
                    occurrenceOrder: 'Orden de ocurrencia',
                    minValue: 'Valor mínimo: 1',
                },
            },
            tableColumn: {
                name: 'Nombre',
                severity: 'Severidad',
                filterset: 'Filter Set',
                type: 'Tipo',
                notification: 'Notificación',
            },
            timeUnit: {
                seconds: 'Segundos',
                minutes: 'Minutos',
                hours: 'Horas',
                days: 'Días',
                weeks: 'Semanas',
                months: 'Meses',
            },
        },
        details: {
            label: 'Detalles',
            affectedCases: 'Casos afectados',
            lastDetected: 'Última detección',
            triggerConditions: 'Condiciones de activación',
            events: 'Eventos',
            selectCaseIdMessage: 'Por favor, selecciona un Id de caso para ver sus eventos',
            caseId: 'ID de caso',
            viewOnTraceViewer: 'Ver en el Visor de Trazas',
        },
        notifications: {
            created: 'Alerta creada correctamente',
            updated: 'Alerta modificada correctamente',
            dismissSuccess: 'Evaluación descartada correctamente',
            dismissAllSuccess: 'Evaluaciones descartadas correctamente',
            removeSuccess: 'Alerta eliminada correctamente',
            validateError: 'Por favor, rellena los campos adecuadamente',
        },
    },
}
