<template>
    <div class="AttributeView q-pa-md">
        <q-btn
            noCaps
            icon="dashboard_customize"
            :loading="attributesLoading"
            :disable="charts.length >= 4 || attributesLoading"
            :label="$t('visualization.attributes.add')"
            color="primary"
            @click="addChart"
        />
        <Transition>
            <div
                v-if="charts.length === 0"
                class="flex justify-center items-center q-ma-md text-h6"
            >
                {{ $t('visualization.attributes.noCharts') }}
            </div>
        </Transition>
        <transition-group
            name="fade"
            tag="div"
            class="row wrap q-mt-md q-col-gutter-md"
        >
            <div
                v-for="(chart, index) in charts"
                :key="chart.id"
                class="col-6"
            >
                <AttributeComponent
                    :attributes="attributes"
                    :categoricalAttributes="categoricalAttributes"
                    :processId="processId"
                    :index="index"
                    :configuration="chart.configuration"
                    @remove="removeChart"
                    @update="updateChart"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import AttributeComponent from './AttributeComponent.vue'

export default {
    name: 'AttributeView',
    components: {
        AttributeComponent,
    },
    props: {
        projectId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        attributes: VueTypes.array,
        categoricalAttributes: VueTypes.array,
        attributesLoading: VueTypes.bool.def(true),
        savedCharts: VueTypes.array,
    },
    emits: ['updateCharts'],
    data () {
        return {
            charts: [],
        }
    },
    mounted () {
        this.loadCharts()
    },
    methods: {
        addChart () {
            if (this.charts.length < 4) {
                this.charts.push({
                    id: Date.now(),
                    configuration: null,
                })
                this.$emit('updateCharts', [...this.charts])
            }
        },
        removeChart (index) {
            this.charts.splice(index, 1)
            this.$emit('updateCharts', [...this.charts])
        },
        updateChart ({ index, configuration }) {
            this.charts[index].configuration = configuration
            this.$emit('updateCharts', [...this.charts])
        },
        loadCharts () {
            if (this.savedCharts) {
                this.charts.push(...this.savedCharts)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.AttributeView {
    width: 100%;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
