<template>
    <div>
        <QTabPanels
            v-model="tab"
            class="TracesSection flex items-center"
            animated
            transitionNext="fade"
            transitionPrev="fade"
            @transition="handleChangeTab"
        >
            <QTabPanel name="TABLE">
                <TracesTable
                    ref="tracesTable"
                    :isLoading="isLoading"
                    :filteredTraces="filteredTraces"
                    :processId="processId"
                    :caseId="caseId"
                    @download="openExport"
                    @switch-view="toggleView"
                    @dataset-from-filtered="generateDataset"
                />
                <AttributeSelector
                    ref="attrSelector"
                    :attrs="attributes"
                    @export="handleDownload"
                />
            </QTabPanel>
            <QTabPanel name="PLOT">
                <TracesPlot
                    :processId="processId"
                    :activities="activities"
                    :colors="colors"
                    @switch-view="toggleView"
                />
            </QTabPanel>
        </QTabPanels>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { filtersStorageMixin } from '@/mixins'
import { download } from '@/services/download'
import { getColorArray } from '@/services/colormap'
import TracesTable from './components/TracesTable.vue'
import AttributeSelector from './components/AttributeSelector.vue'
import TracesPlot from './components/TracesPlot.vue'

const DEFAULT_TAB = 'TABLE'
const TABS = ['TABLE', 'PLOT']

export default {
    name: 'TracesSection',
    components: { TracesTable, AttributeSelector, TracesPlot },
    mixins: [filtersStorageMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        filteredTraces: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        attributes: VueTypes.array,
    },
    data () {
        return {
            isLoading: false,
            tab: DEFAULT_TAB,
            activities: null,
            colors: null,
            caseId: undefined,
        }
    },
    beforeMount () {
        // if (this.$route.params.selectedCaseId) {
        //     this.caseId = this.$route.params.selectedCaseId
        // }
    },
    created () {
        if (sessionStorage) {
            const selectedCaseId = sessionStorage.getItem('selectedCaseId')
            this.caseId = selectedCaseId
        }
    },
    mounted () {
        this.getActivities()
    },
    unmounted () {
        sessionStorage.removeItem('selectedCaseId')
    },
    methods: {
        async getActivities () {
            const datasetId = this.processId
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = { filters, filterSets: filterSetsUUIDs, operator: generalOperator }
            apiRequest(Api().datasets.activities({ datasetId, params }))
                .then((data) => {
                    this.activities = data.map(x => x.id).sort()
                    this.colors = getColorArray(this.activities.length)
                })
                .catch(notifyError)
        },
        async generateDataset (name) {
            const datasetId = this.processId
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = {
                filters,
                name,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }
            notifySuccess(this.$t('visualization.traces.creatingDataset'))
            apiRequest(Api().datasets.createChild({ datasetId, params }))
                .then(() => {
                    notifySuccess(this.$t('visualization.traces.datasetCreated'))
                })
                .catch(notifyError)
        },
        openExport () {
            this.$refs.attrSelector.open()
        },
        handleDownload (selAttrs) {
            this.isLoading = true
            notifySuccess(this.$t('visualization.traces.downloadStarted'))
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const attributes = selAttrs
            apiRequest(Api().visualizations.downloadTraces({
                processId: this.processId,
                params: {
                    filters,
                    filterSets: filterSetsUUIDs,
                    operator: generalOperator,
                    attributes,
                },
            }))
                .then((data) => {
                    download({ name: this.processId, data })
                    notifySuccess(this.$t('visualization.traces.downloadOK'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        toggleView (value) {
            [this.tab] = TABS.filter(x => x !== value)
        },
        handleChangeTab (newTab, oldTab) {
            if (newTab !== oldTab) {
                this.caseId = undefined
            }
        },
    },
}
</script>
<style scoped lang="scss">
.TracesSection {
    width: 100%;
}

</style>
