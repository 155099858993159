<!-- eslint-disable max-len -->
<template>
    <div class="AlertsContainer">
        <h5 class="Title">
            {{ $t('booster.alerts.active') + ': ' + total }}
        </h5>
        <q-splitter
            v-model="splitterModel"
        >
            <template
                #before
                class="Content Tree"
            >
                <div class="q-pa-md">
                    <div>
                        <QInput
                            ref="filterText"
                            v-model="filterText"
                            class="input q-pa-md"
                            dense
                            filled
                            label="Filter"
                        >
                            <template #append>
                                <q-icon
                                    v-if="filterText !== ''"
                                    name="clear"
                                    class="cursor-pointer"
                                    @click="resetFilter"
                                />
                            </template>
                        </QInput>
                    </div>
                    <QTree
                        v-model:selected="selected"
                        :nodes="filteredTreeData"
                        nodeKey="id"
                        class="TreeStyle q-pa-md"
                        noSelectionUnset
                        :filter="filterText"
                        :filterMethod="filterTree"
                        selectedColor="primary"
                        :expanded.sync="expandedNodes"
                    />
                </div>
            </template>

            <template
                #after
                class="Content List"
            >
                <div v-if="selected">
                    <q-tab-panels
                        v-model="selected"
                        class="q-pt-none"
                        animated
                        transitionPrev="jump-up"
                        transitionNext="jump-up"
                    >
                        <q-tab-panel
                            v-for="node in flattenNodes(filteredTreeData)"
                            :key="node.id"
                            class="q-pt-none"
                            :name="node.id"
                        >
                            <AlertList
                                ref="AlertList"
                                :processId="processId"
                                :showDismissed="false"
                                :attributes="attributes"
                                :selectedNode="selectedNode"
                                :selectedSeverityNode="selectedSeverityNode"
                                @createdAlert="onCreatedAlert"
                                @removedAlert="onRemovedAlert"
                                @dismissedAlert="onDismissedAlert"
                                @dismissedAllAlerts="onDismissedAllAlerts"
                            />
                        </q-tab-panel>
                    </q-tab-panels>
                </div>
                <div v-else>
                    <AlertList
                        ref="AlertList"
                        :processId="processId"
                        :showDismissed="false"
                        :attributes="attributes"
                        :selectedNode="selectedNode"
                        :selectedSeverityNode="selectedSeverityNode"
                        @createdAlert="onCreatedAlert"
                        @removedAlert="onRemovedAlert"
                        @dismissedAlert="onDismissedAlert"
                        @dismissedAllAlerts="onDismissedAllAlerts"
                    />
                </div>
            </template>
        </q-splitter>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import AlertList from './AlertList.vue'

export default {
    name: 'Alerts',
    components: {
        AlertList,
    },
    props: {
        attributes: VueTypes.oneOfType([VueTypes.array, VueTypes.object]),
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    setup () {
        return {
            splitterModel: 30,
        }
    },
    data () {
        return {
            alerts: [],
            total: 0,
            filterBy: null,
            filterByType: null,
            filterText: '',
            filteredTreeData: [],
            expandedNodes: [],
            treeData: [],
            selected: null,
            selectedTab: null,
            selectedNode: null,
            selectedSeverityNode: null,
        }
    },
    computed: {
    },
    watch: {
        filterText () {
            this.expandAllNodes()
        },
        selected (newSelected) {
            this.getNodeName(newSelected)
        },
    },
    mounted () {
        this.fetchData(true)
        this.expandAllNodes()
    },
    beforeUnmount () {
        this.$storage.remove('pagination-page')
        this.$storage.remove('pagination-rowsPerPage')
    },
    methods: {
        async fetchData (resetTree = false) {
            const datasetId = this.processId
            apiRequest(Api().alerts.list({ datasetId }))
                .then(({ data, total }) => {
                    this.alerts = data || []
                    this.total = total || 0
                    this.filteredTreeData = this.transformResponseToTreeData(this.alerts)
                    if (resetTree) {
                        this.selected = this.filteredTreeData[0]?.id || null
                        this.selectedNode = { id: null, name: null }
                    }
                    this.expandAllNodes()
                })
                .catch(notifyError)
        },
        onCreatedAlert (alertSeverityId) {
            if (alertSeverityId) {
                this.selected = alertSeverityId
                this.selectedNode = { id: null, name: null }
                this.selectedSeverityNode = alertSeverityId
                this.fetchData()
            } else {
                this.fetchData(true)
            }
        },
        onRemovedAlert (alertId) {
            const parent = this.filteredTreeData[0].children.find(parentNode => parentNode.children.some(child => child.id === alertId))
            if (parent) {
                const severityId = this.filteredTreeData[0].children.find(parentNode => parentNode.id === parent.id).id
                this.selected = severityId
                this.selectedNode = { id: null, name: null }
                this.selectedSeverityNode = severityId
                this.fetchData()
            }
        },
        onDismissedAlert () {
            this.fetchData()
        },
        onDismissedAllAlerts () {
            this.fetchData()
        },
        updateFilter (filter) {
            this.filterBy = filter
        },
        transformResponseToTreeData (response) {
            const riskLevels = {
                label: `${this.$t('booster.alerts.all')} (${ this.total})`,
                id: 'ALL',
                icon: 'warning',
                iconColor: 'primary',
                children: [{
                    label: this.$t('booster.alerts.severity.high'),
                    id: 'HIGH',
                    icon: 'circle',
                    iconColor: 'red',
                    children: [],
                    total: 0,
                },
                {
                    label: this.$t('booster.alerts.severity.medium'),
                    id: 'MEDIUM',
                    icon: 'circle',
                    iconColor: 'orange',
                    children: [],
                    total: 0,
                },
                {
                    label: this.$t('booster.alerts.severity.low'),
                    id: 'LOW',
                    icon: 'circle',
                    iconColor: 'yellow',
                    children: [],
                    total: 0,
                }],
            }

            const severityMap = {
                HIGH: response.high,
                MEDIUM: response.medium,
                LOW: response.low,
            }

            Object.keys(severityMap).forEach((severity) => {
                // eslint-disable-next-line no-nested-ternary
                const childIndex = severity === 'HIGH' ? 0 : severity === 'MEDIUM' ? 1 : 2
                const alerts = severityMap[severity]

                alerts.forEach((alert) => {
                    const entry = {
                        id: `${alert.id}_${alert.name}`,
                        label: `${alert.name} (${alert.triggerCount})`,
                    }

                    riskLevels.children[childIndex].children.push(entry)

                    riskLevels.children[childIndex].total += alert.triggerCount
                })
            })

            riskLevels.children = riskLevels.children.map(child => ({
                ...child,
                label: `${child.label.split('(')[0].trim()} (${child.total})`,
            }))
            return [riskLevels]
        },
        flattenNodes (nodes) {
            return nodes.reduce((flatNodes, node) => [
                ...flatNodes,
                node,
                ...(node.children ? this.flattenNodes(node.children) : []),
            ], [])
        },
        filterTree (node, filter) {
            return node.label.toLowerCase().includes(filter.toLowerCase())
        },
        expandAllNodes () {
            this.expandedNodes = this.getAllNodeIds(this.filteredTreeData)
        },
        getAllNodeIds (nodes) {
            return nodes.reduce((ids, node) => {
                const currentIds = [node.id]
                const childrenIds = node.children ? this.getAllNodeIds(node.children) : []
                return [...ids, ...currentIds, ...childrenIds]
            }, [])
        },
        getNodeName (node) {
            if (node) {
                if (node.includes('_')) {
                    // eslint-disable-next-line prefer-destructuring
                    this.selectedNode.id = node.split('_')[0]
                    // eslint-disable-next-line prefer-destructuring
                    this.selectedNode.name = node.split('_')[1]
                    // eslint-disable-next-line prefer-destructuring
                    this.selectedSeverityNode = null
                } else {
                    this.selectedSeverityNode = node !== 'ALL' ? node : null
                    this.selectedNode = { id: null, name: null }
                }
            }
        },
        resetFilter () {
            this.filterText = ''
            this.expandAllNodes()
        },
    },
}
</script>
<style scoped lang="scss">
.TreeStyle {
    padding-top: 0px;
}
.AlertsContainer {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 3vh;
    overflow: hidden;
    height: fit-content;
    flex-wrap: nowrap;
}
.Title {
    color: $primary;
    width: 100%;
    margin-bottom: 5vh;
}
.Content {
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
}

</style>
