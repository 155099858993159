<template>
    <div class="Plot column content-stretch q-col-gutter-md q-ma-md">
        <div class="flex justify-between items-end UpperBar col-auto">
            <QCard flat>
                <QCardSection horizontal>
                    <QCardSection class="bg-grey-2">
                        <span class="text-weight-bold">{{ $t('visualization.traces.plot.start') }}</span>
                        {{ startDate }}
                    </QCardSection>
                    <QSeparator
                        vertical
                        class="bg-white"
                    />
                    <QCardSection class="bg-grey-2">
                        <span class="text-weight-bold">{{ $t('visualization.traces.plot.end') }}</span>
                        {{ endDate }}
                    </QCardSection>
                </QCardSection>
            </QCard>
            <div class="flex">
                <QBtn
                    color="primary"
                    icon="table_view"
                    unelevated
                    noCaps
                    :label="$t('visualization.traces.toTable')"
                    class="q-mr-md"
                    @click="switchGraphicalView"
                />
                <QInput
                    v-model="search"
                    dense
                    debounce="300"
                    color="primary"
                    style="width: 220px"
                    outlined
                    clearable
                    clearIcon="close"
                    :placeholder="$t('visualization.traces.table.search.placeholder')"
                    @update:modelValue="getEvents"
                >
                    <template #append>
                        <QIcon name="search" />
                    </template>
                </QInput>
            </div>
        </div>
        <div
            v-if="numEvents > 0"
            class="q-mt-sm col column items-center q-col-gutter-sm"
        >
            <div
                class="ChartContainer"
            >
                <v-chart
                    ref="chart"
                    class="Chart"
                    :option="scatterChartData"
                />
            </div>
            <QSlider
                v-model="page"
                :min="1"
                :max="numPages"
                markers
                reverse
                class="Slider col"
                @change="getEvents"
            />
            <QCard
                flat
                class="col"
            >
                <QCardSection class="text-center bg-grey-2">
                    <span>
                        {{ `${$t('visualization.traces.plot.eventsShown')} ` }}
                    </span>
                    <span class="text-weight-bold">
                        {{ `${startEvent} ` }}
                    </span>
                    <span>
                        {{ `${$t('visualization.traces.plot.eventsTo')} ` }}
                    </span>
                    <span class="text-weight-bold">
                        {{ `${endEvent}` }}
                    </span>
                </QCardSection>
            </QCard>
        </div>
        <div
            v-else-if="!isLoading"
        >
            <div class="flex justify-center items-cemter q-mt-lg">
                <WText
                    :size="18"
                >
                    {{ $t('visualization.traces.plot.noEvents') }}
                </WText>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import { filtersStorageMixin, validatorsMixin } from '@/mixins'

const chartDimensions = ['traceKey', 'start', 'activity']

const SORT_DEFAULT = 'START_TIME'

const scatterChartOption = {
    title: {
        text: '',
        left: 'center',
    },
    toolbox: {
        feature: {
            dataZoom: {},
            saveAsImage: {},
        },
    },
    legend: {
        show: false,
    },
    dataset: {
        dimensions: chartDimensions,
        source: [],
    },
    tooltip: {
        trigger: 'item',
        formatter: (params) => {
            const { value, color } = params
            if (value) {
                const dateString = moment(value[1]).format('YYYY-MM-DD HH:mm:SS')
                return `<div style="border-bottom: 1px solid rgba(255,255,255,.3);
                font-size: 18px;padding-bottom: 7px;margin-bottom: 7px;color: ${color}">${value[2]}</div>
                <span style="font-weight: bold;">${chartDimensions[0]} :</span> ${value[0]} <br>
                <span style="font-weight: bold;">${chartDimensions[1]} :</span> ${dateString} <br>`
            }
            return ''
        },
    },
    xAxis: {
        type: 'time',
        axisLine: {
            show: true,
        },
        splitLine: {
            show: true,
        },
        axisPointer: {
            show: true,
            triggerTooltip: false,
        },
    },
    yAxis: {
        type: 'category',
        scale: 'true',
        axisLabel: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        splitLine: {
            show: true,
        },
        axisPointer: {
            show: true,
            label: {
                show: false,
            },
            triggerTooltip: false,
        },
    },
    grid: {
        show: true,
    },
    series: {
        type: 'scatter',
    },
    dataZoom: [
        {
            type: 'slider',
            show: true,
            start: 0,
            end: 100,
            xAxisIndex: 0,
            filterMode: 'filter',
            handleSize: 10,
        },
        {
            type: 'slider',
            yAxisIndex: 0,
            start: 0,
            end: 100,
            filterMode: 'filter',
            handleSize: 10,
        },
    ],
}

export default {
    name: 'TracesPlot',
    components: {
    },
    mixins: [filtersStorageMixin, validatorsMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        activities: VueTypes.array,
        colors: VueTypes.array,
    },
    emits: ['switchView'],
    data () {
        return {
            scatterChartData: scatterChartOption,
            isLoading: false,
            events: null,
            total: 0,
            page: 1,
            rowsPerPage: 5000,
            search: '',
        }
    },
    computed: {
        numPages () {
            return Math.ceil(this.total / this.rowsPerPage)
        },
        startDate () {
            if (this.events) {
                return this.events.length > 0 ? moment(this.events[this.events.length - 1][1]).format('YYYY-MM-DD HH:mm:SS') : '-'
            }
            return '-'
        },
        endDate () {
            if (this.events) {
                return this.events.length > 0 ? moment(this.events[0][1]).format('YYYY-MM-DD HH:mm:SS') : '-'
            }
            return '-'
        },
        numEvents () {
            return this.events ? this.events.length : 0
        },
        startEvent () {
            const startCalc = this.total - this.page * this.rowsPerPage
            return startCalc > 0 ? startCalc : 1
        },
        endEvent () {
            return this.startEvent === 1 ? this.total - (this.numPages - 1) * this.rowsPerPage : this.startEvent + this.rowsPerPage
        },
    },
    beforeMount () {
        this.getEvents()
        this.setActivities()
    },
    mounted () {
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        setActivities () {
            this.scatterChartData = {
                ...this.scatterChartData,
                visualMap: [{
                    show: false,
                    type: 'piecewise',
                    categories: this.activities,
                    left: null,
                    right: 0,
                    inRange: {
                        color: this.colors,
                    },
                }],
            }
        },
        handleResize () {
            if (this.$refs.chart) {
                setTimeout(() => {
                    this.$refs.chart.resize()
                }, 500)
            }
        },
        async getEvents () {
            const { processId } = this
            const params = this.formatParams()
            this.isLoading = true
            apiRequest(Api().visualizations.traces({ processId, params }))
                .then(({ data, total }) => {
                    // Remove UTC from dates
                    const events = this.formatRows(data)
                        .map((e) => {
                            const [first, second, ...rest] = e
                            return [first, second.replace('Z', ''), ...rest]
                        })
                    this.total = total
                    this.scatterChartData = {
                        ...this.scatterChartData,
                        dataset: {
                            dimensions: chartDimensions,
                            source: events,
                        },
                    }
                    this.events = events
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        switchGraphicalView () {
            this.$emit('switchView', 'PLOT')
        },
        formatRows (data) {
            return data.map(({ traceKey, start, activity }) => ([traceKey, start, activity]))
        },
        formatParams () {
            const { page, rowsPerPage } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            return {
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                attributes: [],
                search: this.search || '',
                sortBy: SORT_DEFAULT,
            }
        },
    },
}
</script>
<style scoped lang="scss">

.Plot {
    height: fit-content;
    width: 95%;
}

.UpperBar {
    width: calc(100vw - 350px);
}

.ChartContainer {
    width: 95%;
    height: 65vh;
    flex-grow: 1;
    background-color: #fbfbfb;
    .Chart {
        width: 95%;
        height: 95%;
    }
}

.Slider {
    width: 95%;
}

</style>
