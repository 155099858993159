<template>
    <div class="Container">
        <div class="TopContainer">
            <div class="ChartTitle">
                <label class="ChartTitleLabel">
                    {{ $t('booster.prediction.timeToMeet.timeToMeet') }}
                </label>
            </div>
            <QSelect
                v-if="selAttr"
                v-model="predictedPerformanceBy"
                dense
                outlined
                :options="performanceByOptions"
                @update:model-value="getData"
            />
        </div>
        <div class="ChartFilter">
            <strong
                v-if="predictedPerformanceBy.value === 'best'"
                class="ChartLabel"
            >
                {{ $t('booster.prediction.timeToMeet.improvementRate') }}
                {{ predictedPerformanceBy.label }}
                {{ $t('booster.prediction.timeToMeet.improvementRateSecondPart') }}
            </strong>
            <strong
                v-else
                class="ChartLabel"
            >
                {{ $t('booster.prediction.timeToMeet.improvementRateSla') }} {{ predictedPerformanceBy.label }} {{ $t('booster.prediction.timeToMeet.improvementRateSecondPart') }}
            </strong>
            <QSelect
                v-model="unitMonths"
                dense
                outlined
                :options="months"
                @update:model-value="getData"
            />
            <strong class="ChartLabel">{{ $t('booster.prediction.timeToMeet.months') }}</strong>
        </div>
        <div
            v-if="error"
            class="Error"
        >
            {{ error }}
        </div>
        <div
            v-else
            class="ChartContainer"
        >
            <q-inner-loading :showing="isLoading" />
            <v-chart
                v-if="!isLoading"
                ref="timeToMeet"
                :option="timeToMeetOptions"
                class="Chart"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError,
} from '@/api'

export default {
    name: 'TimeToMeet',
    components: {},
    props: {
        selectedAttr: VueTypes.string,
        processId: VueTypes.string,
    },
    data () {
        return {
            selAttr: undefined,
            open: false,
            error: undefined,
            predictedPerformanceBy: {
                label: this.$t('booster.prediction.timeToMeet.improvementRateSlaLabel'),
                value: 'sla',
            },
            performanceByOptions: [{
                label: this.$t('booster.prediction.timeToMeet.bestPerforming') + this.selAttr,
                value: 'best',
            },
            {
                label: this.$t('booster.prediction.timeToMeet.improvementRateSlaLabel'),
                value: 'sla',
            }],
            unitMonths: 3,
            isLoading: false,
            months: [3, 6, 9, 12],
            timeToMeetOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        tooltipContent += `${(params[0].name)}<br>`
                        params.forEach((param) => {
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            tooltipContent += `${moment.duration(value, 'seconds').format('d[d] h[h]:m[m]:s[s]',
                                { largest: 2, trim: false })} <br>`
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                title: {
                    left: 'center',
                    textStyle: {
                        color: '#33699a',
                        fontSize: 12,
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                        interval: 0,
                        width: 50,
                        hideOverlap: true,
                        overflow: 'break',
                    },
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                yAxis: {
                    show: false,
                },
                dataZoom: [
                    {
                        type: 'slider',
                        showDetail: false,
                        showDataShadow: false,
                        bottom: 10,
                        height: 15,
                    }],
                grid: {
                    left: 25,
                    right: 25,
                    bottom: 30,
                    top: 15,
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: 10,
                            formatter: params => `${moment.duration(params.data, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })}`,
                        },
                        itemStyle: {
                            borderColor: '#0b5b9e',
                            color: '#438AC8',
                            borderRadius: [3, 3, 3, 3],
                        },
                        silent: true,
                    },
                ],
            },
        }
    },
    watch: {
        selectedAttr (value) {
            if (value) {
                this.selAttr = value
                this.performanceByOptions[0].label = this.$t('booster.prediction.timeToMeet.bestPerforming') + this.selAttr
                this.getData()
            }
        },
    },
    mounted () {
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount  () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            if (this.$refs.timeToMeet) {
                setTimeout(() => {
                    this.$refs.timeToMeet.resize()
                }, 500)
            }
        },
        getData () {
            const params = { attribute: this.selAttr, mode: this.predictedPerformanceBy.value, timeInterval: this.unitMonths }
            this.resetOptions()
            this.isLoading = true
            apiRequest(Api().booster.timeToMeet({ processId: this.processId, params }))
                .then((res) => {
                    this.timeToMeetOptions.xAxis.data = res.rows.map(i => i.value)
                    this.timeToMeetOptions.series[0].data = res.rows.map(i => i.timeToMeet)
                })
                .catch((e) => {
                    if (e.result.response.data.errorCode === 'DE0077') {
                        this.error = e.result.response.data.errorMessage
                    } else {
                        notifyError()
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        resetOptions () {
            this.timeToMeetOptions.xAxis.data = []
            this.timeToMeetOptions.series[0].data = []
            this.error = undefined
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    height:80%;
    width: 100%;
    overflow:hidden;
}
    .Title {
        margin-bottom: 3vh;
    }
    .Container {
        position:relative;
        display: flex;
        flex-direction:column;
        flex-wrap: wrap;
        height: fit-content;
    }
    .Chart2 {
        width:100%;
    }
    .TopContainer {
        display: flex;
        flex-direction: row;
        height: auto;
        padding: 1vh;
        height: fit-content;
    }
    .ChartTitle {
        display:flex;
        align-items: center;
    }
    .ChartFilter {
        display:flex;
        align-items: center;
        padding: 1vh;
        background-color: #fbfbfb;
    }
    .ChartTitleLabel {
        font-size: 15px;
        @media screen and (min-height: $md) {
            font-size: large;
        }
            margin-right: 1em;
    }
    .ChartLabel {
        color:#33699a;
        margin: auto 1%;
    }
    .ChartContainer {
        width: 100%;
        flex-grow: 1;
        margin:0 auto;
        white-space: nowrap;
        .Chart {
            width: 100%;
            height: 90%;
            background-color: #fbfbfb;
            & + .chart {
              margin-left: 30px;
              @media screen and (max-width: $lg) {
                   margin-left: 0px;
              }
            }
      }
    }
    .Error {
        padding: 1vh;
        flex-grow: 1;
        background-color: #fbfbfb;
      }
    .isLoading {
        display: flex;
        top: 75%;
        left: 75%;
        max-width: 50px;
        max-height: 50px;
    }
</style>
