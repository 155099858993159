<template>
    <div
        v-if="visible"
        :style="popupStyle"
        class="popup q-pa-sm"
    >
        <div
            class="flex justify-between"
        >
            <p style="font-size: large; font-weight: bold; padding: 10px;">
                {{ data.name }}
            </p>
            <q-btn
                icon="close"
                flat
                @click="handleClickClose"
            />
        </div>
        <q-inner-loading
            v-if="isLoading"
            :showing="isLoading"
            :style="{zIndex: 1000}"
            visible
        />
        <div
            v-else
        >
            <div class="row justify-center q-mb-sm">
                <div class="row items-center q-pa-md">
                    <p style="font-size: medium; font-weight: bold">
                        {{ $t('visualization.popup.impact.title') }}
                    </p>
                </div>
                <div class="column items-center">
                    {{ $t('visualization.popup.impact.current') }}
                    <q-circular-progress
                        :value="currentWallaceCofPercent"
                        size="45px"
                        :thickness="0.2"
                        :color="severityColors[0]"
                        class="q-ma-md"
                        showValue
                    >
                        <div
                            class="q-mt-sm"
                            style="color: #000000; font-size: 14px;"
                        >
                            {{ currentWallaceCofPercent }}%
                        </div>
                        <q-tooltip>
                            {{ currentWallaceCofPercent }}{{ $t('visualization.popup.impact.currentImpact') }}
                        </q-tooltip>
                    </q-circular-progress>
                    {{ $t(`visualization.popup.impact.level.${severityLevels[0]}`) }}
                </div>
                <div class="column items-center">
                    {{ $t('visualization.popup.impact.total') }}
                    <q-circular-progress
                        :value="totalWallaceCofPercent"
                        size="45px"
                        :thickness="0.2"
                        :color="severityColors[1]"
                        class="q-ma-md"
                        showValue
                    >
                        <div
                            class="q-mt-sm"
                            style="color: #000000; font-size: 14px;"
                        >
                            {{ totalWallaceCofPercent }}%
                        </div>
                        <q-tooltip>
                            {{ totalWallaceCofPercent }}{{ $t('visualization.popup.impact.totalImpact') }}
                        </q-tooltip>
                    </q-circular-progress>
                    {{ $t(`visualization.popup.impact.level.${severityLevels[1]}`) }}
                </div>
            </div>
            <div
                class="buttons_container q-gutter-sm q-mx-sm"
            >
                <div class="button_row">
                    <q-btn
                        :color="isFilterApplied[0] ? 'grey-7' : 'primary'"
                        icon="check_box"
                        :label="$t('visualization.popup.buttons.contain')"
                        class="q-mr-sm"
                        @click="handleClickFilter('TRACE_CONTAINS_ARCS',0)"
                    />
                    <span style="margin-left: 10px">{{ casesValue.contains }} cases</span>
                </div>
                <div class="button_row">
                    <q-btn
                        :color="isFilterApplied[1] ? 'grey-7' : 'primary'"
                        icon="disabled_by_default"
                        :label="$t('visualization.popup.buttons.not_contain')"
                        class="q-mr-sm"
                        @click="handleClickFilter('TRACE_NOT_CONTAINS_ARCS',1)"
                    />
                    <span style="margin-left: 10px">{{ casesValue.notContains }} cases</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import { filtersStorageMixin } from '@/mixins'

export default {
    name: 'ArcPopUp',
    mixins: [filtersStorageMixin],
    props: {
        data: VueTypes.object,
        visible: VueTypes.bool,
        position: VueTypes.object,
        tab: VueTypes.string,
    },
    emits: ['close', 'add-filter', 'delete-filter'],
    data () {
        return {
            isLoading: false,
            currentWallaceCofPercent: 0,
            totalWallaceCofPercent: 0,
            severityLevels: ["low", "low"],
            severityColors: ["green", "green"],
            casesValue: {
                contains: 0,
                notContains: 0,
            },
            isFilterApplied: [false, false],
            filters: [],
        }
    },
    computed: {
        popupStyle () {
            return {
                left: `${this.position.x}px`,
                top: `${this.position.y}px`,
            }
        },
    },
    watch: {
        tab () {
            if (this.visible) {
                this.handleClickClose()
            }
        },
        visible (newVal) {
            if (newVal === true) {
                this.getImpactFromWallaceCof()
                this.applyFilters()
                this.getAppliedFilters()
            }
        },
        data () {
            this.getImpactFromWallaceCof()
            this.applyFilters()
            this.getAppliedFilters()
        },
        visualizationFilters: {
            handler () {
                this.getAppliedFilters()
            },
        },
        currentWallaceCofPercent () {
            this.calculateSeverityLevel()
        },
        totalWallaceCofPercent () {
            this.calculateSeverityLevel()
        },
    },

    methods: {
        getImpactFromWallaceCof () {
            this.isLoading = true
            this.currentWallaceCofPercent = 0
            this.totalWallaceCofPercent = 0
            const { processId } = this.$route.params
            const { variantCount: variants, variantUid: variantsId, loopUid: loopId } = this.data?.modelData || {}
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            this.currentFilters = filters
            const arcName = this.formatArcName(this.data.name)

            const params = {
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...(variants && { variants }),
                ...(variantsId && { variantsId }),
                ...(loopId && { loopId }),
            }
            Api().visualizations.arcImpactWallaceCof({ processId, arcName, params })
                .then((data) => {
                    this.currentWallaceCofPercent = Math.round(data?.data.data[0].currentCW * 100)
                    this.totalWallaceCofPercent = Math.round(data?.data.data[0].totalCW * 100)
                }).catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        formatArcName (name) {
            return encodeURIComponent(name)
        },
        getAppliedFilters () {
            this.isFilterApplied = [false, false]

            this.visualizationFilters.forEach((filter) => {
                if (filter?.id === 'TRACE_CONTAINS_ARCS' && filter.type.arc[0] === this.data.name) {
                    this.isFilterApplied[0] = true
                    if (!this.filters.includes(filter)) this.filters.push(filter)
                }

                if (filter.id === 'TRACE_NOT_CONTAINS_ARCS' && filter.type.arc[0] === this.data.name) {
                    this.isFilterApplied[1] = true
                    if (!this.filters.includes(filter)) this.filters.push(filter)
                }
            })
        },

        async applyFilters () {
            try {
                const filters = [
                    'TRACE_CONTAINS_ARCS',
                    'TRACE_NOT_CONTAINS_ARCS',
                ]

                const promises = filters.map(filter => this.filterCases(filter))

                await Promise.all(promises)
                    .then((results) => {
                        this.casesValue = {
                            contains: results[0].cases,
                            notContains: results[1].cases,
                        }
                    })
            } catch (err) {
                notifyError(err)
            }
        },

        async filterCases (filter) {
            const { processId } = this.$route.params
            const { loopUid } = this.data?.modelData
            const { variantCount } = this.data?.modelData
            const { variantUid } = this.data?.modelData
            const params = {
                filters: [
                    {
                        id: filter,
                        values: [this.data.name],
                    },
                ],
                filterSet: [],
                operator: "AND",
                ...(loopUid !== undefined ? { loopUid } : {}),
                ...(variantCount ? { variantCount } : {}),
                ...(variantUid ? { variantUid } : {}),
            }
            return apiRequest(Api().visualizations.modelInfo({ processId, params }))
        },

        getSeverity (percent) {
            if (percent <= 30) {
                return { level: "low", color: "green" }
            } if (percent > 30 && percent <= 60) {
                return { level: "medium", color: "orange" }
            }
            return { level: "high", color: "red" }
        },

        calculateSeverityLevel () {
            const currentSeverity = this.getSeverity(this.currentWallaceCofPercent)
            const totalSeverity = this.getSeverity(this.totalWallaceCofPercent)
            this.severityLevels[0] = currentSeverity.level
            this.severityColors[0] = currentSeverity.color
            this.severityLevels[1] = totalSeverity.level
            this.severityColors[1] = totalSeverity.color
        },

        handleClickFilter (selectedFilter, i) {
            if (this.isFilterApplied[i]) {
                this.isFilterApplied[i] = false
                this.handleDeleteFilter(selectedFilter)
            } else {
                this.isFilterApplied[i] = true
                this.handleAddFilter(selectedFilter)
            }
        },

        formatFilter (selectedFilter, del) {
            const filter = {
                id: selectedFilter,
                label: selectedFilter,
                value: [this.data.name],
                type: {
                    category: null,
                    date: "",
                    duration: "0000D 00h:00m:00s",
                    constraints: {
                        label: "MENOR QUE",
                        value: "LESSER_THAN",
                    },
                    arc: [this.data.name],
                    variant: null,
                    loopName: null,
                    activitySource: null,
                    sourceOccurrence: null,
                    activityTarget: null,
                    activityOccurrence: null,
                    activitiesDurationAlgorithm: "ActivityOccurrence",
                },
                uuid: null,
                pending: null,
            }
            if (del) {
                this.filters.forEach((f) => {
                    if (f.id === filter.id && f.arc === filter.arc) {
                        filter.uuid = f.uuid
                        filter.pending = false
                    }
                })
            }
            return filter
        },

        checkIfExistFilter (selectedFilter) {
            const { visualizationFilters } = this
            return visualizationFilters.some(f => f.id === selectedFilter.id && f.arc === selectedFilter.arc)
        },

        async handleAddFilter (selectedFilter) {
            if (this.checkIfExistFilter(this.formatFilter(selectedFilter, false))) {
                return
            }
            await this.$emit('add-filter', this.formatFilter(selectedFilter, false))
            this.filters.push(this.visualizationFilters[this.visualizationFilters.length - 1])
        },

        handleDeleteFilter (selectedFilter) {
            this.$emit('delete-filter', this.formatFilter(selectedFilter, true))
            this.filters = this.filters.filter(fil => fil !== selectedFilter)
        },

        handleClickClose () {
            this.$emit('close')
        },

    },
}
</script>
<style lang="scss">
     .popup {
        border: 1px solid black;
        border-radius: 5px;
        background: white;
        position: absolute;
        padding: '10px';
        overflow: hidden;
        min-width: max-content;
    }

    .buttons_container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
    }

    .button_row {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .button_row .q-btn {
        width: 170px;
    }
</style>
