<template>
    <div class="Organizations">
        <div class="Actions flex row-stretch-center mb-1">
            <q-btn
                icon="add_circle_outline"
                noCaps
                color="primary"
                :label="$t('organizations.new')"
                @click="handleNewItem"
            />
            <div>
                <q-btn-group
                    v-model="groupOwnerType"
                >
                    <q-btn
                        v-for="option in groupOwnerTypes"
                        :key="option.value"
                        :label="option.label"
                        textColor="primary"
                        :class="['qbtngroup', { active: option.value === groupOwnerType }]"
                        size="0.9em"
                        @click="handleChageGroupOwnerType(option)"
                    />
                </q-btn-group>
            </div>
        </div>
        <div class="Container">
            <OrganizationsLists
                ref="table"
                :rows="organizations"
                :total="total"
                :search="header.search"
                :loading="isFetching"
                @on-new-item="handleNewItem"
                @on-delete="handleDeleteItem"
                @on-update="handleUpdateItem"
                @on-click="handleClickItem"
                @on-fetch-data="fetchData"
            />
        </div>
        <ModalRequest
            ref="delPrDialog"
            :title="$t('organizations.delete')"
            :text="$t('organizations.modals.delete.text', { name: (selected || {}).name })"
            :loading="isLoading"
            @on-accept="deleteOrganization"
        />
        <ModalNameDesc
            ref="editPrDialog"
            withoutDesc
            :title="$t('organizations.edit')"
            :action="$t('actions.save')"
            :loading="isLoading"
            @on-submit="updateOrganization"
        />
        <ModalNameDesc
            ref="newPrDialog"
            withoutDesc
            :title="$t('organizations.new')"
            :action="$t('actions.create')"
            :loading="isLoading"
            @on-submit="createOrganization"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { ModalRequest, ModalNameDesc } from '@/components'
import { dialogMixin } from '@/mixins'
import { OrganizationsLists } from './sections'

export default {
    name: 'Organizations',
    components: {
        ModalRequest,
        ModalNameDesc,
        OrganizationsLists,
    },
    mixins: [dialogMixin],
    props: {
        filters: VueTypes.shape({
            search: VueTypes.string.def(''),
            archived: VueTypes.bool.def(false),
        }),
    },
    data () {
        return {
            organizations: undefined,
            total: undefined,
            isFetching: false,
            isLoading: false,
            showAllOrganizations: true,
            groupOwnerTypes: [
                { label: this.$t('organizations.createdByMe'), value: 'createdByMe' },
                { label: this.$t('organizations.sharedWithMe'), value: 'sharedWithMe' },
            ],
            groupOwnerType: 'createdByMe',
            header: {
                search: '',
                archived: false,
            },
        }
    },
    computed: {
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return {
                ...this.filters,
                ...pagination,
                shared: this.groupOwnerType !== 'createdByMe',
                onlyActive: this.onlyActive,
            }
        },
    },
    watch: {
        filters (value) {
            if (value) this.fetchData()
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        handleChageGroupOwnerType ({ value }) {
            this.groupOwnerType = value
            this.fetchData()
        },
        fetchData () {
            const formattedParams = this.formatParams(this.params)
            this.isFetching = true
            apiRequest(Api().organizations.list(formattedParams))
                .then(({ data, total }) => {
                    this.organizations = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        formatParams (params) {
            const {
                onlyActive, archived, search, page, rowsPerPage, shared,
            } = params
            return {
                shared,
                onlyActive,
                search: search || null,
                includeArchived: !!archived,
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
            }
        },
        async createOrganization ({ name }) {
            this.isLoading = true
            apiRequest(Api().organizations.create({ name }))
                .then(() => {
                    notifySuccess(this.$t('organizations.notifications.created.text'))
                    this.fetchData()
                    this.$refs.newPrDialog.close()
                })
                .catch((error) => {
                    notifyError(error)
                })
                .finally(() => (this.isLoading = false))
        },
        async updateOrganization ({ uuid, name, description }) {
            this.isLoading = true
            apiRequest(Api().organizations.update({ organizationUid: uuid, params: { name } }))
                .then(() => {
                    notifySuccess(this.$t('organizations.notifications.updated.text'))
                    this.fetchData()
                    this.$refs.editPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async deleteOrganization () {
            const { uuid } = this.selected
            this.isLoading = true
            apiRequest(Api().organizations.delete({ organizationUid: uuid }))
                .then(() => {
                    notifySuccess(this.$t('organizations.notifications.deleted.text'))
                    this.fetchData()
                    this.$refs.delPrDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        handleClickItem (organization) {
            const { uuid } = organization
            this.$router.push({ name: 'Organization', params: { organizationId: uuid } })
        },
    },
}
</script>

<style scoped lang="scss">

.qbtngroup {
    border: 1px solid $border-color;
    text-transform: none;
    border-radius: $border-radius;
    font-weight: bold;
    border: 0;
    padding: 7px;
    border-radius: 0;
}
.qbtngroup.active {
  background-color: rgba($primary, 0.1);
}
</style>
