<template>
    <div
        v-if="!isLoading"
        class="row"
    >
        <div
            class="col-sm-5 q-pa-sm"
        >
            <div class="text-subtitle1 text-center q-mb-sm">
                {{ $t('visualization.conformance.fulfillment.percentageTitle') }}
            </div>
            <v-chart
                class="chart"
                :option="pieChartData"
                autoresize
            />
        </div>
        <div
            class="col-sm-7 q-pa-sm"
        >
            <div class="text-subtitle1 text-center q-mb-sm">
                {{ $t('visualization.conformance.fulfillment.percentageTimeTitle') }}
            </div>
            <v-chart
                class="chart"
                :option="barChartData"
                autoresize
            />
            <div
                class="flex items-center justify-center"
            >
                <QSelect
                    v-model="timeModel"
                    :options="timeUnits"
                    :label="$t('visualization.conformance.fulfillment.selectUnit')"
                    style="width: 50%;"
                    @update:modelValue="changeTimeUnit"
                />
            </div>
        </div>
    </div>
    <div
        v-else
        class="row"
    >
        <div class="col">
            <QLinearProgress
                indeterminate
                color="primary"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest,
} from '@/api'
import { filtersStorageMixin, validatorsMixin } from '@/mixins'
import Colors from '@/styles/colors'

const pieChartOption = {
    tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
    },
    legend: {
        orient: 'vertical',
        left: 'left',
    },
}

const barChartOption = {
    title: {
        text: '',
        left: 'center',
    },
    legend: {
        orient: 'vertical',
        left: 'right',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    xAxis: {
        type: 'value',
        splitLine: {
            lineStyle: {
                type: 'dashed',
            },
        },
        nameLocation: 'middle',
        nameTextStyle: {
            align: 'left',
            verticalAlign: 'top',
        },
    },
    yAxis: {
        type: 'value',
    },
    dataZoom: [
        {
            type: 'slider',
            show: true,
            start: 80,
            end: 100,
            handleSize: 8,
        },
    ],
}

export default {
    name: 'Overview',
    components: {
    },
    mixins: [filtersStorageMixin, validatorsMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        modelId: VueTypes.string,
    },
    data () {
        return {
            pieChartData: pieChartOption,
            barChartData: barChartOption,
            isLoading: false,
            timeModel: undefined,
        }
    },
    computed: {
        timeUnits () {
            return [
                {
                    label: this.$t('visualization.conformance.fulfillment.months'),
                    value: 'month',
                },
                {
                    label: this.$t('visualization.conformance.fulfillment.weeks'),
                    value: 'week',
                },
                {
                    label: this.$t('visualization.conformance.fulfillment.days'),
                    value: 'day',
                },
            ]
        },
    },
    watch: {
        modelId () {
            this.setConformanceInfo()
        },
    },
    mounted () {
        [this.timeModel] = this.timeUnits
        this.setConformanceInfo()
    },
    methods: {
        async fetchConformanceInfo () {
            this.isLoading = true
            const { processId } = this
            const { modelId } = this
            const timeUnit = this.timeModel.value
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            apiRequest(Api().conformance.checkModel({
                datasetId: processId,
                bpmnId: modelId,
                params: {
                    timeSplit: timeUnit,
                    filters,
                    filterSets: filterSetsUUIDs,
                    operator: generalOperator,
                },
            }))
                .then((data) => {
                    this.pieChartData = {
                        ...this.pieChartData,
                        series: [
                            {
                                name: this.$t('visualization.conformance.fulfillment.fulfillment'),
                                type: 'pie',
                                radius: '50%',
                                data: [
                                    {
                                        value: data.all.correct.absolute,
                                        name: this.$t('visualization.conformance.fulfillment.correct'),
                                    },
                                    {
                                        value: data.all.incorrect.absolute,
                                        name: this.$t('visualization.conformance.fulfillment.incorrect'),
                                    },
                                ],
                                color: [Colors.primary, Colors.tertiary],
                            },
                        ],
                    }
                    const sortedData = [...Object.entries(data.data)].sort((a, b) => {
                        if (a < b) {
                            return -1
                        }
                        if (a > b) {
                            return 1
                        }
                        return 0
                    })
                    const timeIntervals = sortedData.map(x => x[0])
                    const validPercentage = sortedData.map(x => Math.round(x[1].correct.percentage * 100))
                    const invalidPercentage = sortedData.map(x => Math.round(x[1].incorrect.percentage * 100))
                    this.barChartData = {
                        ...this.barChartData,
                        xAxis: [
                            {
                                type: 'category',
                                axisTick: { show: false },
                                data: timeIntervals,
                            },
                        ],
                        dataZoom: [
                            {
                                start: timeIntervals.length > 15 ? 80 : 0,
                                end: 100,
                                handleSize: 8,
                            },
                        ],
                        series: [
                            {
                                name: this.$t('visualization.conformance.fulfillment.validPercentageTime'),
                                type: 'bar',
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                color: Colors.primary,
                                data: validPercentage,
                            },
                            {
                                name: this.$t('visualization.conformance.fulfillment.invalidPercentageTime'),
                                type: 'bar',
                                emphasis: {
                                    focus: 'series',
                                },
                                color: Colors.tertiary,
                                data: invalidPercentage,
                            },
                        ],
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        setConformanceInfo () {
            this.fetchConformanceInfo()
        },
        changeTimeUnit () {
            this.fetchConformanceInfo()
        },
    },
}
</script>
<style scoped lang="scss">
.chart {
    min-width: 350px;
    height: 380px;
    width: 100%;
}
</style>
