<template>
    <div
        id="bpmn"
        ref="viewer"
    >
        <QBtn
            v-if="centerButton"
            id="reset-zoom-button"
            icon="adjust"
            unelevated
            color="white"
            :class="tabClass"
            textColor="primary"
            size="lg"
            padding="xs"
            round
        >
            <QTooltip>
                {{ $t('visualization.centerChart') }}
            </QTooltip>
        </QBtn>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer'
import { notifyError } from '@/api'

export default {
    name: 'BpmnViewer',
    components: {
    },
    props: {
        data: VueTypes.string,
        centerButton: VueTypes.bool.def(true),
    },
    data () {
        return {
            viewer: undefined,
            tabClass: undefined,
        }
    },
    computed: {
    },
    watch: {
        data () {
            this.calcCenterGraphButtonClass()
            try {
                this.viewer.importXML(this.data).then(() => {
                    this.calcHeightAndWidth()
                    this.viewer.get('canvas').zoom('fit-viewport')
                    this.setGraphPosition()
                })
            } catch (err) {
                this.handleError(err)
            }
        },
    },
    mounted () {
        this.calcCenterGraphButtonClass()
        this.viewer = new BpmnViewer({
            container: '#bpmn',
        })
        try {
            this.viewer.importXML(this.data).then(() => {
                this.calcHeightAndWidth()
                this.setGraphPosition()
                this.viewer.get('canvas').zoom('fit-viewport')
            })
        } catch (err) {
            this.handleError(err)
        }
        document.getElementById('reset-zoom-button').addEventListener('click', () => {
            this.viewer.get('canvas').zoom('fit-viewport')
            this.setGraphPosition()
        })
    },
    methods: {
        handleError (err) {
            notifyError(this.$t('visualization.bpmnNotShowable: ') + err)
        },

        setGraphPosition () {
            if (this.$parent.$options.name === 'QCardSection') {
                this.viewer.get('canvas').scroll({ dx: 0, dy: 70 })
            } else {
                const canvas = this.viewer.get('canvas')
                const viewbox = canvas.viewbox()
                const containerHeight = canvas.getContainer().clientHeight
                const contentHeight = viewbox.height
                const diff = (containerHeight - contentHeight) / 2
                const y = viewbox.y + diff
                canvas.scroll({ dx: 0, dy: y })
            }
        },

        calcHeightAndWidth () {
            const container = document.querySelector('#bpmn')
            if (this.$parent.$options.name === 'QCardSection') {
                container.style.height = `300px`
                container.style.width = `100%`
            } else {
                container.style.height = `300px`
                container.style.width = `100%`
            }
        },

        calcCenterGraphButtonClass () {
            this.tabClass = this.$parent.$options.name === 'BaseTransition' ? 'CenterBtnConformance' : 'CenterBtnModelPreview'
        },
    },
}
</script>
<style scoped lang="scss">
    #bpmn {
        position: relative;
        border: 2px solid $tertiary;
        border-radius: 10px;
        box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    }
    .CenterBtnConformance {
        position: absolute;
        background-color: transparent;
        top: 0px;
        right: 0px;
        z-index: 1;
    }
    .CenterBtnModelPreview {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
    }
</style>
