<template>
    <div class="q-mt-lg">
        <QTable
            v-model:pagination="pagination"
            class="my-sticky-header-table"
            :rows="rows"
            :columns="columns"
            :filter="filter"
            :rowsPerPageOptions="rowsPerPageOptions"
            :loading="tableIsLoading"
            rowKey="name"
            flat
            outlined
            separator="vertical"
            bordered
            wrapCells
            @request="emitRequest"
        >
            <template #body-cell-start="props">
                <QTd :props="props">
                    {{ App.dateLocationFormat(props.value,'long') }}
                </QTd>
            </template>
            <template #body-cell-end="props">
                <QTd :props="props">
                    {{ App.dateLocationFormat(props.value, 'long') }}
                </QTd>
            </template>
            <template #top-left>
                <WText
                    :size="14"
                    color="primary"
                >
                    <WText
                        :size="16"
                        tag="span"
                        weight="bold"
                        color="primary"
                    >
                        {{ $t('visualization.traces.table.total', { rowsNumber, filteredTraces }) }}
                    </WText>
                    {{ $t('visualization.traces.table.totalDesc') }}
                </WText>
            </template>
            <template #top-right>
                <QBtn
                    color="primary"
                    icon="scatter_plot"
                    unelevated
                    noCaps
                    :label="$t('visualization.traces.toPlot')"
                    class="q-mr-sm"
                    @click="switchGraphicalView"
                />
                <QBtn
                    color="primary"
                    icon="add_circle"
                    noCaps
                    outline
                    class="q-mr-sm"
                    @click="prompt = true"
                >
                    <QTooltip>
                        {{ $t('visualization.traces.toDataSet') }}
                    </QTooltip>
                </QBtn>
                <QBtn
                    color="primary"
                    icon="archive"
                    noCaps
                    outline
                    class="q-mr-sm"
                    @click="downloadData"
                >
                    <QTooltip>
                        {{ $t('visualization.traces.downloadCSV') }}
                    </QTooltip>
                </QBtn>
                <QInput
                    v-model="filter"
                    dense
                    debounce="300"
                    color="primary"
                    style="width: 220px"
                    outlined
                    clearable
                    clearIcon="close"
                    :placeholder="$t('visualization.traces.table.search.placeholder')"
                >
                    <template #append>
                        <QIcon name="search" />
                    </template>
                </QInput>
            </template>
            <template #no-data="{ message }">
                <div class="full-width row flex-center text-accent q-gutter-sm">
                    <span>
                        {{ message }}
                    </span>
                </div>
            </template>
        </QTable>
        <QDialog
            v-model="prompt"
            persistent
        >
            <QCard
                style="min-width: 350px"
            >
                <QToolbar>
                    <QToolbarTitle
                        class="text-subtitle1"
                    >
                        {{ $t('visualization.traces.createDataSet') }}
                    </QToolbarTitle>
                    <QBtn
                        flat
                        round
                        dense
                        icon="close"
                        @click="prompt = false"
                    />
                </QToolbar>
                <QCardSection class="q-pt-none">
                    <QInput
                        ref="newDatasetName"
                        v-model="newDatasetName"
                        dense
                        autofocus
                        :rules="[val => !!val || $t('visualization.traces.nameRequired'),
                                 val => val.length > 2 || $t('visualization.traces.nameLength')]"
                    />
                </QCardSection>
                <QCardActions
                    align="right"
                    class="text-primary"
                >
                    <QBtn
                        flat
                        noCaps
                        :label="$t('visualization.traces.cancel')"
                        @click="prompt = false"
                    />
                    <QBtn
                        color="primary"
                        unelevated
                        noCaps
                        :label="$t('visualization.traces.create')"
                        @click="createDataset"
                    />
                </QCardActions>
            </QCard>
        </QDialog>
    </div>
</template>

<script>
import { exportFile } from 'quasar'
import VueTypes from 'vue-types'
import { filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError,
} from '@/api'

function wrapCsvValue (val, formatFn) {
    let formatted = formatFn !== undefined
        ? formatFn(val)
        : val

    formatted = formatted === undefined || formatted === null
        ? ''
        : String(formatted)

    formatted = formatted.split('"').join('""')
    /**
    * Excel accepts \n and \r in strings, but some other CSV parsers do not
    * Uncomment the next two lines to escape new lines
    */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`
}

const ROWS_PER_PAGE_OPTIONS = [
    30, 50, 75, 100, 250, 500,
]

export default {
    name: 'TracesTable',
    mixins: [filtersStorageMixin],
    inject: ['App'],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        filteredTraces: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        isLoading: VueTypes.bool.def(false),
        caseId: VueTypes.string,
    },
    emits: ['download', 'switchView', 'datasetFromFiltered'],
    data () {
        return {
            columns: [],
            rows: [],
            rowsNumber: 0,
            filter: '',
            page: 1,
            rowsPerPage: 30,
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            prompt: false,
            newDatasetName: undefined,
            tableIsLoading: this.isLoading,
        }
    },
    computed: {
        pagination: {
            get () {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    rowsNumber: this.rowsNumber,
                }
            },
            set (value) {
                this.page = value.page
                this.rowsPerPage = value.rowsPerPage
            },
        },
    },
    watch: {
        isLoading (value) {
            this.tableIsLoading = value
        },
    },
    beforeMount () {
        if (this.caseId !== undefined) {
            this.filter = this.caseId
        }
        this.getEvents()
    },
    methods: {
        async getEvents (params) {
            const { processId } = this
            const formattedParams = params
                ? this.formatParams(params)
                : this.formatParams({
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    search: this.filter,
                })
            this.tableIsLoading = true
            apiRequest(Api().visualizations.traces({ processId, params: formattedParams }))
                .then(({ data, total }) => {
                    if (!data.length) {
                        this.rows = data
                        this.rowsNumber = total
                        return
                    }
                    this.columns = this.formatColumns(data)
                    this.rows = this.formatRows(data)
                    this.rows = this.rows.map(r => ({
                        ...r,
                        start: r.start.replace('Z', ''),
                        end: r.end.replace('Z', ''),
                        // The 'traceKey' exception should depend on the type of data that should be received from the backend in the 'configurations' call
                        ...Object.fromEntries(Object.entries(r).filter(([key]) => key !== 'start' && key !== 'end' && key !== 'traceKey')
                            .map(([key, value]) => [key, this.App.numberLocationFormat(value, true)])),
                    }))
                    this.rowsNumber = total
                })
                .catch(notifyError)
                .finally(() => (this.tableIsLoading = false))
        },
        emitRequest (props) {
            const rowsPerPage = props.pagination.rowsPerPage === 0 ? 9999 : props.pagination.rowsPerPage
            this.pagination = props.pagination
            this.getEvents({ ...props.pagination, rowsPerPage, search: props.filter })
        },
        formatParams (params) {
            const { page, rowsPerPage, search } = params
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            return {
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || '',
            }
        },
        formatRows (data) {
            return data.map(({ attributes, ...restData }) => ({ ...restData, ...attributes }))
        },
        formatColumns (data) {
            const [{ attributes, ...rest }] = data
            return [
                ...Object.keys(rest).map(f => this.formatField(f, 'primary')),
                ...Object.keys(attributes).map(attrKey => this.formatField(attrKey, 'attribute')).sort((a, b) => a.name.localeCompare(b.name)),
            ]
        },
        formatField (field, type) {
            return {
                name: field,
                label: field,
                field,
                align: 'center',
                format: val => (val ?? '-'),
                headerClasses: `${type === 'primary' ? 'text-white bg-primary' : 'bg-grey-4'}`,
                classes: type === 'primary' ? 'bg-light-blue-1 ellipsis' : 'bg-grey-1 ellipsis',
                style: 'max-width: 500px',
                headerStyle: 'max-width: 300px',
            }
        },
        downloadData () {
            this.$emit('download')
        },
        exportTable () {
            // naive encoding to csv format
            const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
                this.rows.map(row => this.columns.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === undefined ? col.name : col.field],
                    col.format,
                )).join(',')),
            ).join('\r\n')

            const status = exportFile(
                'table_export.csv',
                content,
                'text/csv',
            )

            if (status !== true) {
                this.$q.notify({
                    message: this.$t('visualization.traces.downloadDenied'),
                    color: 'negative',
                    icon: 'warning',
                })
            }
        },
        switchGraphicalView () {
            this.$emit('switchView', 'TABLE')
        },
        createDataset () {
            if (this.$refs.newDatasetName.validate()) {
                const { newDatasetName } = this
                this.$emit('datasetFromFiltered', newDatasetName)
                this.prompt = false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.my-sticky-header-table {
    height: fit-content;
    max-height: calc(79vh - $sidebar-height);
    @media screen and (min-height: $md) {
        max-height:calc(83vh - $sidebar-height);
    }
    max-width: calc(100vw - 350px);
    justify-items: center;
    &:deep(thead) {
        position: sticky;
        top: 0;
        background: white;
        z-index: 99;
    }
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
        background-color: #c1f4cd;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}
</style>
