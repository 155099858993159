export default api => ({
    list: ({ datasetId }) => api.get(`/datasets/${datasetId}/alerts`),
    get: ({ datasetId, alertId }) => api.get(`/datasets/${datasetId}/alerts/${alertId}`),
    create: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/alerts`, params),
    update: ({ datasetId, alertId, params }) => api.put(`/datasets/${datasetId}/alerts/${alertId}`, params),
    delete: ({ datasetId, alertId }) => api.delete(`/datasets/${datasetId}/alerts/${alertId}`),
    getAlertTriggers: ({ processId, params }) => api.post(`/datasets/${processId}/alerts/visualization`, params),
    dismiss: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/alerts/evaluations/dismiss`, params),
    dismissAll: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/alerts/evaluations/dismiss-all`, params),
    evaluations: ({ datasetId, params }) => api.post(`/datasets/${datasetId}/alerts/evaluations`, params),
    evaluationDetails: ({ processId, params }) => api.post(`/datasets/${processId}/alerts/evaluations/details`, params),
})
