<template>
    <QCard
        flat
        bordered
    >
        <QCardSection>
            <QSelect
                v-model="selectedValue"
                optionLabel="name"
                optionValue="name"
                class="Select"
                :disable="isLoading"
                :options="usableAttribs"
                @update:modelValue="notifyAttrChange"
            />
        </QCardSection>
        <QCardSection class="CauseListing">
            <QList v-if="infoData">
                <QItemSection
                    v-if="causeIndex === loadingIndex && isLoading"
                    class="col"
                >
                    <QLinearProgress
                        indeterminate
                        color="secondary"
                    />
                </QItemSection>
                <QItem
                    v-for="(item, idx) in infoData.data"
                    v-else
                    :key="idx"
                >
                    <QItemSection>
                        <QItemLabel>
                            {{ item.attributeValue }}
                        </QItemLabel>
                        <QLinearProgress
                            :value="elementPercentage(item.attributeCount,total)"
                            size="20px"
                            rounded
                            color="secondary"
                        />
                        <QItemLabel class="text-caption text-italic">
                            {{ `${App.numberLocationFormat(item.attributeCount)} ${occurenceLiteral(item.attributeCount)}` }}
                        </QItemLabel>
                    </QItemSection>
                </QItem>
            </QList>
        </QCardSection>
    </QCard>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'CategoricalCause',
    components: {
    },
    inject: ['App'],
    props: {
        usableAttribs: VueTypes.array,
        defaultValue: VueTypes.string,
        totalEvents: VueTypes.number,
        causeIndex: VueTypes.number,
        attrData: VueTypes.object,
        isLoading: VueTypes.bool.def(false),
    },
    emits: ['changed'],
    data () {
        return {
            selectedValue: undefined,
            infoData: null,
            total: null,
            loadingIndex: undefined,
        }
    },
    computed: {
    },
    watch: {
        attrData () {
            this.infoData = this.attrData
            this.total = this.totalEvents
        },
        isLoading (value) {
            if (!value) {
                this.loadingIndex = undefined
            }
        },
    },
    mounted () {
        this.selectedValue = this.defaultValue
        this.infoData = this.attrData
        this.total = this.totalEvents
    },
    methods: {
        notifyAttrChange (value) {
            this.loadingIndex = this.causeIndex
            this.$emit('changed', { value, idx: this.causeIndex })
        },
        occurenceLiteral (value) {
            return value !== 1 ? this.$t('visualization.conformance.issuesView.cases') : this.$t('visualization.conformance.issuesView.case')
        },
        elementPercentage (occurrences, total) {
            return occurrences / total
        },
    },
}
</script>
<style scoped lang="scss">
.CauseListing {
    overflow: scroll;
    height: 300px;
}
.Select {
    &:deep(span) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: fit-content;
    }
}
</style>
