<template>
    <div class="column q-col-gutter-md q-pa-md">
        <div class="col-sm-12">
            <QSelect
                v-model="datasetsNameSelected"
                :options="datasetsName"
                :label="$t('visualization.contextComparison.datasets')"
                :dense="true"
                @update:modelValue="getDatasetFilters"
            />
        </div>
        <div class="col-sm-12">
            <div v-if="setsName.length === 0">
                <QSelect
                    v-model="setSelected"
                    disable
                    :dense="true"
                    :options="datasetsName"
                    :hint="$t('visualization.contextComparison.noFiltersets')"
                    :label="$t('visualization.contextComparison.filtersets')"
                />
            </div>
            <QSelect
                v-else
                v-model="setSelected"
                :dense="true"
                :options="setsName"
                :label="$t('visualization.contextComparison.filtersets')"
                @popup-show="refreshFilterSet"
                @update:modelValue="getModelInfoFiltered"
            />
        </div>
        <div class="col-sm-12">
            <div class="row q-col-gutter-sm">
                <div class="col-sm-6">
                    <span class="ContextualElementName">{{ $t(`visualization.contextComparison.cases`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2 metric"
                    >
                        <QCardSection
                            horizontal
                            class="text-weight-bold"
                        >
                            {{ cases }}
                        </QCardSection>
                        <QCardSection
                            v-if="casesDifference.number !== null"
                            horizontal
                            class="q-pa-sm q-ml-sm bg-white row items-center"
                        >
                            <QIcon
                                :name="casesDifference.direction"
                                color="primary"
                                class="metricIcon"
                            />
                            <span class="text-weight-bold">
                                {{ casesDifference.number }}
                            </span>
                        </QCardSection>
                    </QCard>
                </div>
                <div class="col-sm-6">
                    <span class="ContextualElementName">{{ $t(`visualization.contextComparison.variants`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2 metric"
                    >
                        <QCardSection
                            horizontal
                            class="text-weight-bold"
                        >
                            {{ variants }}
                        </QCardSection>
                        <QCardSection
                            v-if="variantsDifference.number !== null"
                            horizontal
                            class="q-pa-sm q-ml-sm bg-white row items-center"
                        >
                            <QIcon
                                :name="variantsDifference.direction"
                                color="primary"
                                class="metricIcon"
                            />
                            <span class="text-weight-bold">
                                {{ variantsDifference.number }}
                            </span>
                        </QCardSection>
                    </QCard>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <span class="ContextualElementName">{{ $t(`visualization.contextComparison.duration`) }}</span>
            <div class="row q-col-gutter-sm items-center">
                <div class="col-sm-8">
                    <QCard
                        flat
                        class="bg-grey-2 metricDuration"
                    >
                        <QCardSection
                            horizontal
                            class="text-weight-bold"
                        >
                            {{ duration }}
                        </QCardSection>
                        <QCardSection
                            v-if="durationDifference.metric"
                            horizontal
                            class="q-pa-sm q-ml-sm bg-white row items-center"
                        >
                            <QIcon
                                :name="durationDifference.direction"
                                :color="getColorClass(durationDifference.direction)"
                                class="metricIcon"
                            />
                            <span class="text-weight-bold">
                                {{ durationDifference.metric }}
                            </span>
                        </QCardSection>
                    </QCard>
                </div>
                <div class="col-sm-4 text-center">
                    <QFab
                        v-model="displayDurationOptions"
                        :label="metricsDurationSelected.value"
                        square
                        flat
                        labelPosition="left"
                        color="grey-2"
                        textColor="primary"
                        icon="keyboard_arrow_down"
                        direction="up"
                        padding="sm"
                    >
                        <QFabAction
                            v-for="option in metricsDuration"
                            :key="option.value"
                            textColor="primary"
                            color="grey-2"
                            :label="option.label"
                            padding="xs"
                            @click="refreshDuration(option)"
                        />
                    </QFab>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row q-col-gutter-sm">
                <div class="col-sm-6">
                    <div>
                        <span class="ContextualElementName">{{ $t(`visualization.contextComparison.start`) }}</span>
                        <span class="dateDifference">{{ startDateDifference }}</span>
                    </div>
                    <QCard
                        flat
                        class="bg-grey-2 metric"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ startDate }}
                        </div>
                    </QCard>
                </div>
                <div class="col-sm-6">
                    <div>
                        <span class="ContextualElementName">{{ $t(`visualization.contextComparison.end`) }}</span>
                        <span class="dateDifference">{{ endDateDifference }}</span>
                    </div>
                    <QCard
                        flat
                        class="bg-grey-2 metric"
                    >
                        <div class="q-pa-sm text-center text-weight-bold">
                            {{ endDate }}
                        </div>
                    </QCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import { filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError,
} from '@/api'

export default {
    name: 'ContextComparisonCard',
    components: {
    },
    mixins: [filtersStorageMixin],
    inject: ['App'],
    props: {
        filteredInfo: VueTypes.oneOfType([VueTypes.object, VueTypes.array]),
        sectionAssociated: VueTypes.object,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    emits: ['filterSelected', 'csvPosition'],
    data () {
        return {
            displayDurationOptions: false,
            sets: [],
            setsName: [],
            setSelected: null,
            datasets: null,
            currentDataset: null,
            datasetsName: null,
            datasetsNameSelected: null,
            selectedInfoFiltered: null,
            datasetSelected: null,
            filterSelected: null,
            currentUuid: null,
            metricsDuration: [
                { value: 'med', label: this.$t('visualization.filters.contextualInfo.med') },
                { value: 'avg', label: this.$t('visualization.filters.contextualInfo.avg') },
                { value: 'sd', label: this.$t('visualization.filters.contextualInfo.sd') },
                { value: 'min', label: this.$t('visualization.filters.contextualInfo.min') },
                { value: 'max', label: this.$t('visualization.filters.contextualInfo.max') },
            ],
            metricsDurationSelected: {
                label: this.$t('visualization.filters.contextualInfo.avg'),
                value: 'avg',
            },
            cases: '-',
            variants: '-',
            duration: '-',
            endDate: '-',
            startDate: '-',
            variantsDifference: {
                number: null,
                direction: null,
            },
            casesDifference: {
                number: null,
                direction: null,
            },
            durationDifference: {
                metric: null,
                direction: null,
            },
            startDateDifference: '',
            endDateDifference: '',
            noSets: null,
            csvPosition: {},
        }
    },
    computed: {
    },
    mounted () {
        this.getDatasets()
        this.checkIfDataSaved()
    },
    methods: {
        getDatasets () {
            const params = {
                projectUid: this.$route.params.projectId,
            }
            apiRequest(Api().datasets.list(params))
                .then(({ data, total }) => {
                    this.datasets = data.filter(item => item.state.identifier !== 'ERROR')
                    this.datasetsName = this.datasets.map(item => item.name)
                })
                .catch(notifyError)
        },
        refreshFilterSet () {
            this.datasetSelected = this.datasets.find(item => item.name === this.datasetsNameSelected)
            if (this.processId === this.datasetSelected.uuid) {
                this.getDatasetFilters()
            }
        },
        getDatasetFilters () {
            if (this.datasetsNameSelected != null) {
                this.setSelected = ''
                this.datasetSelected = this.datasets.find(item => item.name === this.datasetsNameSelected)
                this.currentDataset = this.datasets.find(item => item.uuid === this.processId)
                this.currentUuid = this.datasetSelected.uuid
                const uuidSelected = this.datasetSelected.uuid
                apiRequest(Api().datasets.getDatasetFilters({ datasetId: uuidSelected }))
                    .then(({ data }) => {
                        this.sets = data
                        if (this.sets.length === 0) {
                            this.noSets = 'noSets'
                        } else { this.noSets = null }
                        this.setsName = data.map(item => item.name)
                    })
                    .catch(notifyError)
                    .finally(() => {
                        this.getModelInfoFiltered()
                    })
            }
        },
        getModelInfoFiltered () {
            const processid = this.currentUuid
            if (this.setSelected) {
                const filters = this.sets.filter(item => item.name === this.setSelected)
                this.filterSelected = { name: filters[0].name, filters: filters[0].filters, uuid: filters[0].uuid }
            } else {
                this.filterSelected = { filters: [] }
            }
            this.emitData()
            apiRequest(Api().visualizations.modelInfo({ processId: processid, params: this.filterSelected }))
                .then((data) => {
                    this.selectedInfoFiltered = data
                })
                .catch(notifyError)
                .finally(() => (
                    this.getContext()
                ))
        },
        emitData () {
            const dataSelected = {
                section: this.sectionAssociated,
                dataset: this.datasetSelected,
                filter: this.filterSelected,
                metricDuration: this.metricsDurationSelected,
            }
            this.$emit('filterSelected', dataSelected)
        },
        checkIfDataSaved () {
            if (this.sectionAssociated.dataset) {
                this.datasetsNameSelected = this.sectionAssociated.dataset.name
                this.datasets = [this.sectionAssociated.dataset]
                this.getDatasetFilters(this.sectionAssociated.dataset.name)
                this.datasetSelected = this.sectionAssociated.dataset
                this.filterSelected = [this.sectionAssociated.filter]
                this.sets = [this.sectionAssociated.filter]
                this.setSelected = this.sectionAssociated.filter.name
                this.metricsDurationSelected = this.sectionAssociated.metricDuration
                this.getModelInfoFiltered()
            }
        },
        getContext () {
            if (this.sectionAssociated.metricDuration) {
                this.metricsDurationSelected = this.sectionAssociated.metricDuration
            } else {
                this.metricsDurationSelected = { value: 'avg', label: this.$t('visualization.filters.contextualInfo.avg') }
            }
            const formatDuration = this.metricsDurationSelected.value
            this.variants = this.formatNumber(this.selectedInfoFiltered.variants)
            this.cases = this.formatNumber(this.selectedInfoFiltered.cases)
            this.duration = this.formatDuration(this.selectedInfoFiltered.duration[formatDuration])
            this.endDate = this.App.dateLocationFormat(this.selectedInfoFiltered.maxEndDateTime)
            // eslint-disable-next-line max-len
            this.startDate = this.App.dateLocationFormat(this.selectedInfoFiltered.minStartDateTime)
            this.compareMetrics(formatDuration)
        },
        compareDirection (value) {
            let icon = ''
            if (value < 0) {
                icon = 'arrow_upward'
            } else if (value > 0) {
                icon = 'arrow_downward'
            } else {
                icon = 'remove'
            }
            return icon
        },
        getColorClass (icon) {
            let color = 'primary'
            if (icon === 'arrow_upward') {
                color = 'red'
            } if (icon === 'arrow_downward') {
                color = 'green'
            }
            return color
        },
        compareMetrics (formatDuration) {
            const differenceVariant = this.filteredInfo.variants - this.selectedInfoFiltered.variants
            this.variantsDifference.number = this.formatNumber(Math.abs(differenceVariant))
            this.variantsDifference.direction = this.compareDirection(differenceVariant)
            const differenceCases = this.filteredInfo.cases - this.selectedInfoFiltered.cases
            this.casesDifference.number = this.formatNumber(Math.abs(differenceCases))
            this.casesDifference.direction = this.compareDirection(differenceCases)
            const durationVariant = this.filteredInfo.duration[formatDuration] - this.selectedInfoFiltered.duration[formatDuration]
            this.durationDifference.metric = this.formatDuration(Math.abs(durationVariant))
            this.durationDifference.direction = this.compareDirection(durationVariant)
            this.endDateDifference = this.compareDate(this.filteredInfo.maxEndDateTime, this.selectedInfoFiltered.maxEndDateTime)
            this.startDateDifference = this.compareDate(this.filteredInfo.minStartDateTime, this.selectedInfoFiltered.minStartDateTime)
            this.csvPosition = {
                id: this.sectionAssociated.sectionId,
                data: {
                    currentDataset: this.currentDataset.name,
                    datasetComparison: this.datasetSelected.name,
                    currentCases: this.formatNumber(Math.abs(this.filteredInfo.cases)),
                    casesComparison: this.cases,
                    casesDifference: this.casesDifference.number,
                    currentVariants: this.formatNumber(Math.abs(this.filteredInfo.variants)),
                    variantsComparison: this.variants,
                    variantsDifference: this.variantsDifference.number,
                    currentDuration: this.formatDuration(this.filteredInfo.duration[this.metricsDurationSelected.value]),
                    durationComparison: this.duration,
                    durationDifference: this.durationDifference.metric,
                    metric: this.metricsDurationSelected.label,
                    currentStartDate: this.formatDate(this.filteredInfo.minStartDateTime),
                    startDateComparison: this.startDate,
                    startDateDifference: this.diffDate(this.filteredInfo.minStartDateTime, this.selectedInfoFiltered.minStartDateTime),
                    currentEndDate: this.formatDate(this.filteredInfo.maxEndDateTime),
                    endDateComparison: this.endDate,
                    endDateDifference: this.diffDate(this.filteredInfo.maxEndDateTime, this.selectedInfoFiltered.maxEndDateTime),
                },
            }
            this.$emit('csvPosition', this.csvPosition)
        },
        formatDate (isoDateString) {
            return moment.utc(isoDateString).format('DD/MM/YYYY')
        },
        diffDate (referenceDate, comparisonDate) {
            const momentReference = moment.utc(referenceDate)
            const momentComparison = moment.utc(comparisonDate)
            return momentReference.diff(momentComparison, 'seconds')
        },
        compareDate (referenceDate, comparisonDate) {
            const momentReference = moment.utc(referenceDate)
            const momentComparison = moment.utc(comparisonDate)
            if (momentReference.diff(momentComparison, 'seconds') > 0) {
                return this.$t('visualization.contextComparison.before')
            } if (momentReference.diff(momentComparison, 'seconds') < 0) {
                return this.$t('visualization.contextComparison.after')
            }
            return ''
        },
        formatNumber (value) {
            let formattedValue = value
            if (formattedValue >= 1000) {
                const abbreviated = (value / 1000).toFixed(1)
                formattedValue = `${this.App.numberLocationFormat(abbreviated)}K`
            }
            return formattedValue
        },
        formatDuration (currentSeconds) {
            const duration = moment.duration(currentSeconds, 'seconds')
            return duration ? duration.format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-'
        },
        refreshDuration (metrics) {
            this.metricsDurationSelected = metrics
            this.duration = this.formatDuration(this.selectedInfoFiltered.duration[metrics.value])
            const durationVariant = this.filteredInfo.duration[metrics.value] - this.selectedInfoFiltered.duration[metrics.value]
            this.durationDifference.metric = this.formatDuration(Math.abs(durationVariant))
            this.durationDifference.direction = this.compareDirection(durationVariant)
            this.emitData()
        },
    },
}
</script>
<style scoped lang="scss">

  .metricIconDuration, .metricIcon{
    font-size: 28px;
  }
 .metricIconDurationEqual{
    margin-left:10%;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 2em;
    color:$primary;
 }

  .metric, .metricDuration {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  .ContextualElementName{
    font-weight: 700;
  }
  .dateDifference{
    color: $primary;
    margin-left: 8px;
    font-size: small;
    font-weight: bold;
    margin-top: 3.8px;
  }
  .metricIcon{
    margin-right: 3px;
  }

</style>
